import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['autocompleteInput'];

  connect() {
    const $input = $(this.autocompleteInputTarget);

    const options = {
      getValue: 'name',
      theme: 'setyl-default',
      highlightPhrase: true,
      adjustWidth: false,
      url: (phrase) => `/physical_asset_manufacturers?q%5Bname_cont%5D=${phrase}`,
    };

    $input.easyAutocomplete(options);
  }
}
