import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  scrollToTop() {
    if (this.hasFormTarget) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
