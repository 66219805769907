/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { config: Object };

  connect() {
    Paddle.Setup({
      vendor: this.configValue.paddleVendorId,
    });

    if (this.configValue.isPaddleSandbox) {
      Paddle.Environment.set('sandbox');
    }

    Paddle.Checkout.open({
      method: 'inline',
      frameTarget: 'paddle-checkout-container',
      product: this.configValue.paddlePlanId,
      email: this.configValue.userEmail,
      country: this.configValue.userCountry,
      postcode: this.configValue.userPostcode,
      allowQuantity: true,
      quantity: this.configValue.membersCount,
      disableLogout: true,
      frameInitialHeight: 450,
      frameStyle: 'width:100%; min-width:286px; background-color: transparent; border: none;',
      passthrough: `{"user_uuid": "${this.configValue.userUuid}", "company_uuid": "${this.configValue.companyUuid}", "company": "${this.configValue.company}"}`,
      title: this.configValue.title,
      message: this.configValue.message,
      success: this.configValue.successUrl,
      coupon: this.configValue.coupon
    });
  }
}
