/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';
import { I18n } from 'i18n-js';
import translations from '../locales/translations.json';

const i18n = new I18n(translations);

export default class extends Controller {
  static targets = ['form'];

  _serializeForm(form) {
    const data = new FormData(form);
    // FIXME: workaround for remote select on the asset survey settings
    // https://gitlab.com/setyl-team/setyl-backend/-/issues/4691
    data.delete('asset_survey_setting[restricted_asset_type_ids][]');
    return new URLSearchParams(data).toString();
  }

  watchChanges(e) {
    const formsCurrentStates = this.formTargets.map((form) => this._serializeForm(form));
    const changedForms = this.formsInitialStates.filter((formInitialState, index) => formInitialState != formsCurrentStates[index]);
    const hasChanges = changedForms.length > 0;
    if (hasChanges) {
      const shouldNavigate = window.confirm(i18n.t('web.settings.ui_messages.are_you_sure_to_exit_without_saving'));
      if (!shouldNavigate) e.preventDefault();
    }
  }

  _updateFormStates() {
    this.formsInitialStates = this.formTargets.map((form) => this._serializeForm(form));
  }

  connect() {
    this.formsInitialStates = this.formTargets.map((form) => this._serializeForm(form));
    this.formTargets.forEach((form) => form.addEventListener('submit', this._updateFormStates.bind(this)));
  }
}
