import { Turbo } from '@hotwired/turbo-rails';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { timeout: 10000 };

  connect() {
    this.timeoutId = setTimeout(() => {
      Turbo.visit(window.location, { action: 'replace' });
    }, this.timeoutValue);
  }

  disconnect() {
    clearTimeout(this.timeoutId);
  }
}
