import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'icon',
   ];

  static outlets = [
    'category--form',
  ];

  handleSelect(e) {
    this.iconTargets.forEach((icon) => {
      icon.classList.remove('bg-gray-300');
    });
    e.currentTarget.classList.add('bg-gray-300');
    this.categoryFormOutlet.inputTarget.value = e.currentTarget.getAttribute('data-name');
    const avatarContainer = this.categoryFormOutlet.avatarTarget;
    avatarContainer.querySelector('img').setAttribute('src', e.currentTarget.getAttribute('data-icon-url'));

    const offcanvas = e.currentTarget.closest('.offcanvas');
    if (offcanvas) {
      const bsOffcanvas = bootstrap.Offcanvas.getInstance(offcanvas);
      if (bsOffcanvas) {
        bsOffcanvas.hide();
      }
    }
  }
}
