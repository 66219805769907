/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import Chartkick from 'chartkick';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
// eslint-disable-next-line import/no-extraneous-dependencies
import merge from 'lodash/merge';

export default class extends Controller {
  static values = {
    label: String,
  };

  _optionsOnXAxisOnDate(date = new Date()) {
    const options = {
      library: {
        plugins: {
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                xMin: date,
                xMax: date,
                borderColor: '#0065f7',
                borderWidth: 1,
                label: {
                  backgroundColor: '#0065f7',
                  display: true,
                  content: this.labelValue,
                  position: 'start',
                  font: { weight: 'normal' },
                },
              },
            },
          },
        },
      },
    };

    return options;
  }

  connect() {
    const element = this.element.querySelector('[id^="chart-"');
    if (!element) return;

    Chart.register(annotationPlugin);

    const chart = Chartkick.charts[element.id];
    const options = chart.getOptions();

    const newOptions = this._optionsOnXAxisOnDate();
    merge(options, newOptions);
    chart.setOptions(options);
  }
}
