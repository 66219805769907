/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  static targets = ['state'];

  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: 'AccountingConnectionChannel',
        id: this.data.get('id'), // FIXME: Refactor to value
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      },
    );
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    const element = this.stateTarget;
    element.innerHTML = data.partial;

    if (data.needs_to_reload_page) {
      Turbo.visit(window.location.toString(), { action: 'replace' });
    }
  }
}
