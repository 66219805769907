/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['selectLicense', 'expandable'];

  openSelect() {
    this.expandableTarget.classList.remove('d-none');
    const bs_dropdown = new bootstrap.Dropdown(this.selectLicenseTarget);
    setTimeout(() => bs_dropdown.show(), 0);
  }

  installLicense(e) {
    const element = e.currentTarget;
    const device_uuid = element.getAttribute('data-device-uuid');
    const license_id = document.getElementById('license_id').value;

    if (license_id) {
      Rails.ajax({
        url: `/physical_assets/${device_uuid}/licenses/${license_id}/install`,
        type: 'PUT',
        success(data) {},
        error(data) {
          alert(data.responseText);
        },
      });
    }
  }
}
