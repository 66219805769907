/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import { Html5QrcodeScanner, Html5QrcodeScanType } from 'html5-qrcode';

const MATCH_REGEX = /^setyl\.it\//gi;

export default class extends Controller {
  static targets = [
    'reader',
    'input',
    'offcanvas',
  ];

  _trimDomainName(text) {
    return text.replace(MATCH_REGEX, '');
  }

  _setInputValue(value) {
    const trimmedValue = this._trimDomainName(value);
    if (trimmedValue.length !== value.length) {
      this.inputTarget.value = trimmedValue;
    } else {
      this.inputTarget.value = value;
    }

    // NOTE: Event triggers algolia autocomplete search. No pop-up is being shown without this
    const event = new Event('input');
    this.inputTarget.dispatchEvent(event);

    // NOTE: Event triggers custom filterbar#performSearch
    const changeEvent = new Event('change');
    this.inputTarget.dispatchEvent(changeEvent);
  }

  _pauseReader() {
    this.html5QrcodeScanner.pause(true);
  }

  _stopReader() {
    if (this.html5QrcodeScanner) this.html5QrcodeScanner.clear();
  }

  _onScanSuccess(decodedText) {
    this._pauseReader();
    this._setInputValue(decodedText);
    this.stopScanning();
  }

  _initializeReader() {
    const reader_id = this.readerTarget.id;
    const config = {
      fps: 10,
      qrbox: { width: 200, height: 200 },
      rememberLastUsedCamera: true,
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
    };

    this.html5QrcodeScanner = new Html5QrcodeScanner(reader_id, config, false);
    this.html5QrcodeScanner.render(this._onScanSuccess.bind(this));
  }

  openOffcanvas() {
    const offcanvas = new bootstrap.Offcanvas(this.offcanvasTarget);
    offcanvas.show();
    this.startScanning();
  }

  startScanning() {
    this._initializeReader();
  }

  stopScanning() {
    this._stopReader();
    if (this.hasOffcanvasTarget) {
      const offcanvas = bootstrap.Offcanvas.getInstance(this.offcanvasTarget);
      if (offcanvas) offcanvas.hide();
    }
  }

  disconnect() {
    this.stopScanning();
  }
}
