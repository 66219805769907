/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { Html5QrcodeScanner, Html5QrcodeScanType, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import Rails from '@rails/ujs';

const MATCH_REGEX = /^setyl\.it\//gi;

export default class extends Controller {
  static targets = [
    'reader',
    'readerStub',
    'input',
    'scanBtn',
    'gotoReviewBtn'
  ];

  _trimDomainName(text) {
    return text.replace(MATCH_REGEX, '');
  }

  _sendRequest(assetId) {
    // eslint-disable-next-line no-restricted-globals
    const matches = location.pathname.match(/\/([a-f\d-]+)/);
    const uuid = matches[1];
    // NOTE: /surveys/abc1-def2-.../asset_scan?asset_id=abc123
    const url = `/surveys/${uuid}/asset_scan?asset_id=${assetId}`;
    Rails.ajax({
      url,
      type: 'GET',
      success(body) {
        Turbo.renderStreamMessage(body);
      },
      error(data) {
        console.error('Failed', data);
        alert('Something went wrong, try again please');
      },
    });
  }

  _pauseReader() {
    this.html5QrcodeScanner.pause(true);
  }

  _stopReader() {
    if (this.html5QrcodeScanner) this.html5QrcodeScanner.clear();
  }

  _onScanSuccess(decodedText) {
    this._pauseReader();
    if (this.hasGotoReviewBtnTarget) {
      this.gotoReviewBtnTarget.classList.remove('btn-outline-primary');
      this.gotoReviewBtnTarget.classList.add('btn-primary');
    }
    const assetId = this._trimDomainName(decodedText);
    this._sendRequest(assetId);
  }

  _initializeReader() {
    const reader_id = 'reader';
    const config = {
      fps: 10,
      qrbox: { width: 150, height: 150 },
      rememberLastUsedCamera: true,
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
    };

    this.html5QrcodeScanner = new Html5QrcodeScanner(reader_id, config, false);
    this.html5QrcodeScanner.render(this._onScanSuccess.bind(this));
  }

  startScanning() {
    this._initializeReader();
    this.scanBtnTarget.classList.add('d-none');
    this.readerStubTarget.classList.add('d-none');
    this.readerTarget.classList.remove('d-none');
  }

  resumeScanning() {
    this.html5QrcodeScanner.resume();
  }

  stopScanning() {
    this._stopReader();
    this.readerTarget.classList.add('d-none');
    this.readerStubTarget.classList.remove('d-none');
  }

  disconnect() {
    this.stopScanning();
  }
}
