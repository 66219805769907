/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'arrowUp',
    'arrowDown',
    'arrow',
    'collapsible',
  ];

  _rotateBootstrapIcon() {
    this.arrowTarget.classList.toggle('bi-chevron-up');
    this.arrowTarget.classList.toggle('bi-chevron-down');
  }

  rotate() {
    if (this.hasArrowDownTarget) this.arrowDownTarget.classList.toggle('d-none');
    if (this.hasArrowUpTarget) this.arrowUpTarget.classList.toggle('d-none');
    if (this.hasArrowTarget) this._rotateBootstrapIcon();
  }

  rotateCollapsed(e) {
    if (e === undefined) return;

    if (this.hasCollapsibleTarget && e.detail.content === this.collapsibleTarget.id) return;

    this.rotate();
  }

  connect() {
    const isActive = this.element.classList.contains('active');
    const isCollapsibleOpen = this.hasCollapsibleTarget && this.collapsibleTarget.classList.contains('show');
    if (isActive || isCollapsibleOpen) this.rotate();
  }
}
