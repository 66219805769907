import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['autocompleteInput', 'brandId', 'logo'];

  static values = {
    isfocused: Boolean,
    hasVisibleApps: Boolean,
  };

  connect() {
    const $input = $(this.autocompleteInputTarget);
    const url = this.hasVisibleAppsValue ? (phrase) => `/app_brands.json?q=${phrase}&has_visible_apps=true` : (phrase) => `/app_brands.json?q=${phrase}`

    const options = {
      getValue: 'name',
      theme: 'setyl-default',
      highlightPhrase: true,
      adjustWidth: false,
      url,
      categories: [
        {
          listLocation: 'brands',
          maxNumberOfElements: 5,
        },
      ],
      template: {
        type: 'iconLeft',
        fields: {
          iconSrc: 'logo',
        },
      },

      list: {
        onChooseEvent: (e) => {
          const selectedData = $input.getSelectedItemData();
          const brandId = selectedData.id;
          const licenseLogoUrl = selectedData.logo;
          this.brandIdTarget.value = brandId;
          this.logoTarget.src = licenseLogoUrl;
        },
      },
    };

    $input.easyAutocomplete(options);
    if (this.isfocusedValue) $input.focus();
  }
}
