/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'searchQuery',
    'container',
  ];

  _hideElement(element) {
    element.classList.add('visually-hidden');
  }

  _showElement(element) {
    element.classList.remove('visually-hidden');
  }

  filterData() {
    const searchQuery = this.searchQueryTarget.value.toUpperCase();
    if (searchQuery.length === 0) this._resetCollapseContainers();

    const { containerTargets } = this;
    containerTargets.forEach((container) => {
      this._showElement(container);

      const options = container.querySelectorAll('.filter-options');
      options.forEach((option) => this._hideElement(option));
      // eslint-disable-next-line max-len
      const matchedOptions = Array.from(options).filter((option) => (option.textContent || option.innerText).toUpperCase().includes(searchQuery));
      matchedOptions.forEach((option) => this._showElement(option));

      this._expandCollapseContainer(container);

      const hasOptionsFound = (matchedOptions.length > 0);
      if (hasOptionsFound) return;

      const filterToggle = container.querySelector('.filter-toggle');
      const isTitleMatched = filterToggle.innerText.toUpperCase().includes(searchQuery);
      if (isTitleMatched) {
        options.forEach((item) => this._showElement(item));
        return;
      }

      this._hideElement(container);
    });
  }

  _expandCollapseContainer(container) {
    const toggleElement = container.querySelector('.filter-toggle');
    const isCollapsed = !toggleElement.ariaExpanded || (toggleElement.ariaExpanded === 'false');
    if (isCollapsed) toggleElement.click();
  }

  _resetCollapseContainers() {
    const { containerTargets } = this;
    containerTargets.forEach((container) => {
      const collapseElement = container.querySelector('.collapse');
      const bsCollapse = bootstrap.Collapse.getOrCreateInstance(collapseElement);
      if (bsCollapse) bsCollapse.hide();
    });
  }

  clearSearch() {
    this.searchQueryTarget.value = '';
    this.filterData();
  }
}
