import { Controller } from '@hotwired/stimulus';
import { I18n } from 'i18n-js';
import translations from '../locales/translations.json';

const i18n = new I18n(translations);

export default class extends Controller {
  static targets = ['text'];
  static values = {
    showToast: Boolean,
  }

  // FIXME: update to use outlet for 'notification--flash' instead of creating toast here
  // Currently notification--flash stimulus controller is connected only if flash has been appeared at least once
  // static outlets = ['notification--flash'];

  _appendFlash(type, content, target) {
    const toast = this._createToast(type, content);
    target.closest('.offcanvas-body').appendChild(toast);
  }

  _createToast(type, content) {
    const toast = document.createElement('div');
    toast.innerHTML = `
      <div aria-atomic="" aria-live="assertive" class="toast toast-${type} fade hide mt-2" data-controller="notification--flash" role="alert">
        <div class="d-flex">
          <div class="toast-body m-auto">
            ${content}
          </div>
          <button aria-label="Close" class="btn-close me-2 align-self-center" data-bs-dismiss="toast" type="button"></button>
        </div>
      </div>
    `;

    return toast;
  }

  // NOTE:
  // Cannot test locally, requires HTTPS (window.isSecureContext = true)
  // If navigator is undefined then it is not supported in browser
  // ---
  // app/views/web/apps/onboardings/questionnaires/_invitation.html.haml:16
  // .card.p-3.mt-3{ 'data-controller': 'clipboard', 'data-clipboard-notification--flash-outlet': '.toast' }

  async copyContent(e) {
    e.preventDefault();
    if (!this.hasTextTarget) return;

    const hasDataValue = this.textTarget.dataset.value !== undefined;
    const text = hasDataValue ? this.textTarget.dataset.value : this.textTarget.innerHTML;
    await navigator.clipboard.writeText(text);
    if (this.showToastValue) {
      this._appendFlash('success', i18n.t('web.apps.onboardings.questionnaire.questionnaire_invitation_copied'), this.element);
    }
  }
}
