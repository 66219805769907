import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    url: String,
  };

  proceed() {
    const user_uuid = this.element.value;
    const nextUrl = `${this.urlValue}/${user_uuid}`;
    window.location.href = nextUrl;
  }
}
