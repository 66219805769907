import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { isVisible: Boolean };

  static targets = ['warrantyExpiresOnDate'];

  switchVisibility(event) {
    this.isVisibleValue = event.target.value == 'on_a_date';
  }

  isVisibleValueChanged() {
    if (!this.hasWarrantyExpiresOnDateTarget) return;

    if (this.isVisibleValue) {
      this.warrantyExpiresOnDateTarget.classList.remove('d-none');
    } else {
      this.warrantyExpiresOnDateTarget.classList.add('d-none');
    }
  }
}
