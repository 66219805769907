/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  _createToast(type, content) {
    const toast = document.createElement('div');
    toast.innerHTML = `
      <div aria-atomic="" aria-live="assertive" class="toast toast-${type} fade hide mt-2" data-controller="notification--flash" role="alert">
        <div class="d-flex">
          <div class="toast-body m-auto">
            ${content}
          </div>
          <button aria-label="Close" class="btn-close me-2 align-self-center" data-bs-dismiss="toast" type="button"></button>
        </div>
      </div>
    `;

    return toast;
  }

  connect() {
    const toast = new bootstrap.Toast(this.element);
    toast.show();
  }
}
