/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

// NOTE: do we really need this one?
export default class extends Controller {
  static targets = ['container'];

  showToast(e) {
    const { type, text } = { ...e.params, ...e.detail };
    const toast = this._createToast(type, text);
    this.containerTarget.appendChild(toast);
  }

  _createToast(type, content) {
    const toast = document.createElement('div');
    toast.innerHTML = `
      <div aria-atomic="" aria-live="assertive" class="toast toast-${type} fade hide" data-controller="notification--flash" role="alert">
        <div class="d-flex">
          <div class="toast-body m-auto">
            ${content}
          </div>
          <button aria-label="Close" class="btn-close me-2 align-self-center" data-bs-dismiss="toast" type="button"></button>
        </div>
      </div>
    `;

    return toast;
  }
}
