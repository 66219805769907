import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'select',
  ];

  toggleSelect(e) {
    if (e.currentTarget.value === '1') {
      this.selectTarget.classList.add('d-none');
      this.selectTarget.querySelector('select').required = false;
    } else {
      this.selectTarget.classList.remove('d-none');
      this.selectTarget.querySelector('select').required = true;
    }
  }
}
