/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import onScan from 'onscan.js';

const MATCH_REGEX = /^setyl\.it\//gi;

export default class extends Controller {
  static targets = ['input'];

  connect() {
    const config = {
      suffixKeyCodes: [9, 13],
      stopPropagation: false,
      captureEvents: true,
      minLength: 6,
      reactToKeydown: true,
      reactToPaste: true,
      keyCodeMapper(oEvent) {
        if (oEvent.which === 190) return '.';
        if (oEvent.which === 191) return '/';
        if (oEvent.which === 173) return '-';
        if (oEvent.which === 109) return '-';
        if (oEvent.which === 189) return '-';

        return onScan.decodeKeyEvent(oEvent);
      },
      onPaste(sPasted, oEvent) {
        const value = sPasted.replace(MATCH_REGEX, '');
        const { target } = oEvent;
        target.value = value;
        const inputEvent = new Event('input');
        const changeEvent = new Event('change');
        target.dispatchEvent(inputEvent);
        target.dispatchEvent(changeEvent);
      },
    };
    onScan.attachTo(this.element, config);
  }

  disconnect() {
    onScan.detachFrom(this.element);
  }

  removeSubstring(e) {
    e.preventDefault();
    const newValue = this._getAssetIdFromEvent(e);
    this._setInputValue(newValue);
  }

  autoSelectFromScan() {
    const newValue = this._getAssetIdFromInput();
    this.dispatch('auto-select', { detail: { asset_id: newValue } });
  }

  autoSelectFromPaste(e) {
    if (this._skipAutoSelect(e)) return true;

    e.preventDefault();
    const newValue = this._getAssetIdFromEvent(e);
    this.dispatch('auto-select', { detail: { asset_id: newValue } });
  }

  _getAssetIdFromInput() {
    const inputValue = this._getInputValue();
    return this._trimDomainName(inputValue);
  }

  _getAssetIdFromEvent(e) {
    if (e.detail) {
      const value = e.detail.scanCode;
      return this._trimDomainName(value);
    }

    const inputValue = this._getInputValue();
    if (inputValue.length > 0) {
      return this._trimDomainName(inputValue);
    }
  }

  _trimDomainName(text) {
    return text.replace(MATCH_REGEX, '');
  }

  _getInputValue() {
    return this.inputTarget.value;
  }

  _setInputValue(value) {
    this.inputTarget.value = value;

    // NOTE: Event triggers algolia autocomplete search. No pop-up is being shown without this
    const inputEvent = new Event('input');
    this.inputTarget.dispatchEvent(inputEvent);

    // NOTE: Event triggers custom filterbar#performSearch
    const changeEvent = new Event('change');
    this.inputTarget.dispatchEvent(changeEvent);
  }

  _skipAutoSelect(e) {
    const paste = (e?.clipboardData || window.clipboardData).getData('text');
    return !paste.match(MATCH_REGEX);
  }
}
