import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    referrer: Boolean,
  };

  open() {
    if (this.referrerValue) this.element.click();
  }
}
