import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'select',
    'restriction',
    'owner',
    'manager',
    'supervisor',
    'employee',
    'noAccess',
  ];

  toggleSelect(e) {
    if (e.currentTarget.value === 'all_records') {
      this.selectTarget.classList.add('d-none');
      this.selectTarget.querySelector('select').required = false;
    } else {
      this.selectTarget.classList.remove('d-none');
      this.selectTarget.querySelector('select').required = true;
    }
  }

  toggleRestriction(e) {
    const targetsToHideRestrictionOn = [
      this.ownerTarget,
      this.employeeTarget,
      this.noAccessTarget,
    ];

    // Find the checked radio button for restrictions
    const restrictionRadioButtons = document.querySelectorAll('input[name="user[restriction_type]"]');
    const checkedRestrictionRadioButton = Array.from(restrictionRadioButtons).find((radio) => radio.checked);

    if (targetsToHideRestrictionOn.includes(e.currentTarget)) {
      this.restrictionTarget.classList.add('d-none');
      this.selectTarget.classList.add('d-none');
      this.selectTarget.querySelector('select').required = false;
    } else {
      // Remove 'd-none' based on the value of the checked radio button
      if (checkedRestrictionRadioButton && checkedRestrictionRadioButton.value === 'restricted_records') {
        this.restrictionTarget.classList.remove('d-none');
        this.selectTarget.classList.remove('d-none');
        this.selectTarget.querySelector('select').required = true;
      } else {
        this.restrictionTarget.classList.remove('d-none');
        this.selectTarget.classList.add('d-none');
        this.selectTarget.querySelector('select').required = false;
      }
    }
  }
}
