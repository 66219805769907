/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import Chartkick from 'chartkick';
// eslint-disable-next-line import/no-extraneous-dependencies
import merge from 'lodash/merge';

// NOTE: For controversial JS code here see https://www.chartjs.org/docs/4.4.0/configuration/tooltip.html#position-modes
const getOrCreateTooltip = (chart) => {
  const existingTooltipEl = chart.canvas.parentNode.querySelector('div');
  if (existingTooltipEl) return existingTooltipEl;

  const newTooltipEl = document.createElement('div');
  chart.canvas.parentNode.appendChild(newTooltipEl);
  return newTooltipEl;
};

const externalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // NOTE: Hide if no tooltip
  if ((tooltip.opacity === 0) || !tooltip.body) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // NOTE: Removing event handlers as well and avoiding memory leak this way
  while (tooltipEl.firstChild) {
    tooltipEl.firstChild.remove();
  }

  const header = document.createElement('h1');
  const title = tooltip.title[0] || '';
  header.innerHTML = title;
  tooltipEl.appendChild(header);

  const bodyLines = tooltip.body.map((item) => item.lines).reverse();
  const labelColorsReversed = tooltip.labelColors.reverse();
  bodyLines.forEach((line, i) => {
    const span = document.createElement('span');
    span.classList.add('chartjs__tooltip_custom__legend-badge');

    const colors = labelColorsReversed[i];
    span.style.background = colors.backgroundColor;

    const p = document.createElement('p');
    p.innerHTML = line;

    const div = document.createElement('div');
    div.classList.add('chartjs__tooltip_custom__line');
    div.appendChild(span);
    div.appendChild(p);
    tooltipEl.appendChild(div);
  });

  tooltipEl.style.opacity = 1;
  tooltipEl.classList.add('chartjs__tooltip_custom');

  const position = context.chart.canvas.getBoundingClientRect();
  tooltipEl.style.left = `${position.left + window.pageXOffset + tooltip.caretX}px`;
  tooltipEl.style.top = `${position.top + window.pageYOffset + tooltip.caretY}px`;
};

export default class extends Controller {
  _externalTooltipOptions() {
    const options = {
      library: {
        plugins: {
          tooltip: {
            enabled: false,
            position: 'average',
            external: externalTooltipHandler,
          },
        },
      },
    };

    return options;
  }

  connect() {
    const element = this.element.querySelector('[id^="chart-"');
    if (!element) return;

    const chart = Chartkick.charts[element.id];
    const options = chart.getOptions();

    const newOptions = this._externalTooltipOptions();
    merge(options, newOptions);
    chart.setOptions(options);
  }
}
