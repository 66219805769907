import { Controller } from '@hotwired/stimulus';

// FIXME: Deprecated, use contract_info_controller.js
export default class extends Controller {
  static targets = ['noticeTerms', 'noticeInterval', 'noticeIntervalNumber', 'endDate'];

  dependentFieldsReset() {
    this.noticeIntervalNumberTarget.removeAttribute('disabled');
    this.noticeIntervalTarget.removeAttribute('disabled');
    this.noticeIntervalTarget.tomselect?.enable();
    this.endDateTarget.removeAttribute('disabled');
  }

  dependentFieldsSetup() {
    this.dependentFieldsReset();

    switch (this.noticeTermsTarget.value) {
      case 'no_terms':
        this.noticeIntervalNumberTarget.setAttribute('disabled', true);
        this.noticeIntervalTarget.setAttribute('disabled', true);
        this.noticeIntervalTarget.tomselect?.disable();
        break;
      case 'rolling':
        this.noticeIntervalNumberTarget.setAttribute('disabled', true);
        this.noticeIntervalTarget.setAttribute('disabled', true);
        this.noticeIntervalTarget.tomselect?.disable();
        this.endDateTarget.setAttribute('disabled', true);
        break;
      default:
        break;
    }
  }

  connect() {
    this.dependentFieldsSetup();
  }
}
