/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'acceptBtn',
    'submitBtn',
    'scrollableContainer',
  ];

  checkPosition() {
    const container = this.scrollableContainerTarget;
    const currentScrollPos = Math.ceil(container.scrollTop + container.clientHeight);
    const isAtBottom = currentScrollPos >= container.scrollHeight;
    if (isAtBottom) this._enableAccept();
  }

  _disableSubmit() {
    this.submitBtnTarget.classList.add('disabled');
  }

  _enableAccept() {
    this.acceptBtnTarget.classList.remove('disabled');
  }

  toggleSubmitButton() {
    this.submitBtnTarget.classList.toggle('disabled');
    this.acceptBtnTarget.classList.toggle('btn-outline-primary_highlighted');
  }

  connect() {
    if (!this.hasScrollableContainerTarget) {
      console.warn('No scrollable container found');
      return;
    }

    const isSubmitDisabled = this.hasAcceptBtnTarget;
    if (isSubmitDisabled) this._disableSubmit();

    const container = this.scrollableContainerTarget;
    const hasNoOverflow = container.scrollHeight <= container.clientHeight;
    if (hasNoOverflow) this._enableAccept();
  }
}
