import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';

export default class extends Controller {
  static values = { url: String, frameId: String };

  connect() {
    Turbo.cache.clear();
    this.#redirect();
    this.element?.remove();
  }

  #redirect() {
    if (this.frameIdValue === '') {
      Turbo.visit(this.urlValue);
      return;
    }

    const frame = document.getElementById(this.frameIdValue);
    if (frame) {
      frame.src = this.urlValue;
    } else {
      Turbo.visit(this.urlValue);
    }
  }
}
