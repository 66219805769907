import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submitBtn'];

  disableSubmit(e) {
    this.submitBtnTarget.classList.add('disabled');
  }

  enableSubmit(e) {
    this.submitBtnTarget.classList.remove('disabled');
  }
}
