/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { config: Object };

  openCheckout() {
    Paddle.Setup({
      vendor: this.configValue.paddleVendorId,
    });

    if (this.configValue.isPaddleSandbox) {
      Paddle.Environment.set('sandbox');
    }

    Paddle.Checkout.open({
      method: 'inline',
      frameTarget: 'paddle-checkout-container',
      frameInitialHeight: 416,
      frameStyle: 'width:100%; min-width:286px; background-color: transparent; border: none;',
      passthrough: `{"user_uuid": "${this.configValue.userUuid}", "company_uuid": "${this.configValue.companyUuid}", "company": "${this.configValue.company}"}`,
      message: this.configValue.message,
      override: this.configValue.updateUrl,
      success: this.configValue.successUrl,
    });
  }
}
