import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'submitButton',
    'quantityInput',
  ];

  refreshQuantity() {
    const submitButton = this.submitButtonTarget;
    const quantityInput = this.quantityInputTarget;
    submitButton.value = `Add ${quantityInput.value} assets`;
  }
}
