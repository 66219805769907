import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['depreciationMethod', 'depreciationPeriod', 'depreciationPeriodInput', 'depreciationAnnualRate', 'depreciationAnnualRateInput', 'depreciationMinimumCost', 'depreciationMinimumCostInput'];

  switchVisibility(event) {
    this.depreciationPeriodTarget.classList.add('d-none');
    this.depreciationPeriodInputTarget.setAttribute('disabled', true);
    this.depreciationAnnualRateTarget.classList.add('d-none');
    this.depreciationAnnualRateInputTarget.setAttribute('disabled', true);
    this.depreciationMinimumCostTarget.classList.add('d-none');
    this.depreciationMinimumCostInputTarget.setAttribute('disabled', true);

    let currentMethod;
    this.depreciationMethodTargets.forEach((el, i) => {
      if (el.checked) {
        currentMethod = el.value;
      }
    });

    if (currentMethod === 'straight_line') {
      this.depreciationPeriodTarget.classList.remove('d-none');
      this.depreciationPeriodInputTarget.removeAttribute('disabled');
      this.depreciationMinimumCostTarget.classList.remove('d-none');
      this.depreciationMinimumCostInputTarget.removeAttribute('disabled');
    } else if (currentMethod === 'declining_balance') {
      this.depreciationPeriodTarget.classList.remove('d-none');
      this.depreciationPeriodInputTarget.removeAttribute('disabled');
      this.depreciationAnnualRateTarget.classList.remove('d-none');
      this.depreciationAnnualRateInputTarget.removeAttribute('disabled');
      this.depreciationMinimumCostTarget.classList.remove('d-none');
      this.depreciationMinimumCostInputTarget.removeAttribute('disabled');
    }
  }

  connect() {
    this.switchVisibility();
  }
}
