import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'contactNameInput',
    'descriptionInput',
    'radio',
  ];

  connect() {
    const contactNameInput = this.contactNameInputTarget;
    const descriptionInput = this.descriptionInputTarget;
    const radios = this.radioTargets;

    radios.forEach((radio) => radio.addEventListener('change', () => {
      if (radio.value == 'description_cont') {
        descriptionInput.removeAttribute('disabled');
        descriptionInput.classList.remove('disabled');
        descriptionInput.closest('.form-group').classList.remove('disabled');

        contactNameInput.setAttribute('disabled', true);
        contactNameInput.classList.add('disabled');
        contactNameInput.closest('.form-group').classList.add('disabled');
      } else if (radio.value == 'accounting_transaction_contact_name_eq') {
        contactNameInput.removeAttribute('disabled');
        contactNameInput.classList.remove('disabled');
        contactNameInput.closest('.form-group').classList.remove('disabled');

        descriptionInput.setAttribute('disabled', true);
        descriptionInput.classList.add('disabled');
        descriptionInput.closest('.form-group').classList.add('disabled');
      }
    }));
  }
}
