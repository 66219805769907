import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['selectLicense', 'expandable'];

  openSelect() {
    this.expandableTarget.classList.remove('d-none');
    const bs_dropdown = new bootstrap.Dropdown(this.selectLicenseTarget);
    setTimeout(() => bs_dropdown.show(), 0);
  }

  assignLicense(e) {
    const element = e.currentTarget;
    const user_uuid = element.getAttribute('data-user-uuid');
    const license_id = document.getElementById('license_id').value;

    if (license_id) {
      Rails.ajax({
        url: `/users/${user_uuid}/licenses/${license_id}/assign`,
        type: 'PUT',
        success(data) {},
        error(data) {
          alert(data.responseText);
        },
      });
    }
  }
}
