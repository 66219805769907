/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['secondaryRadioGroup'];

  switchSecondaryRadioGroup(e) {
    if (e.currentTarget.value === 'embedded') {
      this.secondaryRadioGroupTargets.forEach((element) => {
        element.checked = (element.value === 'no');
      });
    } else if (e.currentTarget.value === 'resale') {
      this.secondaryRadioGroupTargets.forEach((element) => {
        element.checked = (element.value === 'yes');
      });
    }
  }
}
