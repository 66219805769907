import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['upButton', 'downButton'];

  move(event) {
    event.preventDefault();

    this._disableAllButtons();

    const button = event.currentTarget;
    const url = button.dataset.questionUrl;

    fetch(url, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        "Accept": "text/vnd.turbo-stream.html"
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error("Error request");
      }
      return response.text();
    }).then(turboStream => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(turboStream, 'text/html');
      document.body.append(...doc.body.children);
    }).catch((error) => {
      console.error("Error request:", error);
    }).finally(() => {
      this._enableAllButtons();
    });
  }

  _disableAllButtons() {
    this.upButtonTargets.forEach(button => button.style.pointerEvents = 'none');
    this.downButtonTargets.forEach(button => button.style.pointerEvents = 'none');
  }

  _enableAllButtons() {
    this.upButtonTargets.forEach(button => button.style.pointerEvents = '');
    this.downButtonTargets.forEach(button => button.style.pointerEvents = '');
  }
}
