import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const form = this.element;
    form.addEventListener('change', () => {
      form.submit();
      form.querySelectorAll('input').forEach((el) => el.setAttribute('disabled', 'true'));
    });
  }
}
