/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'selectAssetType',
    'assetTypeHeaderText',
    'assetTypeIcon',
    'selectManufacturer',
    'assetTitle',
    'assetSpecifics',
    'assetSpecificsInput',
    'assetModel',
    'assetModelInput',
  ];

  static values = {
    offcanvas: Boolean,
  };

  _updateProfilePageTemplate() {
    const { assetTypeHeaderTextTarget } = this;
    const { assetTypeIconTarget } = this;
    const select = this.selectAssetTypeTarget;
    const selectedOption = select.options[select.selectedIndex];

    const data = {
      image: selectedOption.dataset.image,
      text: selectedOption.text,
      value: selectedOption.value,
    };
    if (!data.value) {
      assetTypeHeaderTextTarget.textContent = data.text;
      return;
    }

    assetTypeIconTarget.setAttribute('src', data.image);
    assetTypeHeaderTextTarget.textContent = data.text;

    this.assetModelInputTarget.addEventListener('focusout', () => {
      this.assetTitleTarget.textContent = `${this.selectManufacturerTarget.value} ${this.assetModelInputTarget.value}`;
    });

    this.assetSpecificsInputTarget.addEventListener('focusout', () => {
      this.assetSpecificsTarget.textContent = this.assetSpecificsInputTarget.value;
    });
  }

  _updateOffcanvasTemplate() {
    const { assetTypeIconTarget } = this;
    const select = this.selectAssetTypeTarget;
    const selectedOption = select.options[select.selectedIndex];

    const data = {
      image: selectedOption.dataset.image,
      text: selectedOption.text,
      value: selectedOption.value,
    };
    if (!data.value) return;

    assetTypeIconTarget.setAttribute('src', data.image);
  }

  updateTemplate() {
    if (this.offcanvasValue) {
      this._updateOffcanvasTemplate();
      return;
    }

    this._updateProfilePageTemplate();
  }

  connect() {
    this.updateTemplate();
  }
}
