/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  static targets = ['card'];

  static values = {
    isNew: Boolean,
    id: Number,
  };

  connect() {
    if (this.isNewValue) return;

    this.subscription = consumer.subscriptions.create(
      {
        channel: 'CompanyIntegrationChannel',
        id: this.idValue,
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      },
    );
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    const element = this.cardTarget;
    element.innerHTML = data.partial;
  }
}
