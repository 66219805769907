import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'searchQuery',
    'container',
    'searchableText',
    'section',
  ];

  filterData() {
    const searchQuery = this.searchQueryTarget.value.toUpperCase();
    const { searchableTextTargets } = this;

    searchableTextTargets.forEach((elem) => {
      const text = elem.textContent || elem.innerText;
      if (text.toUpperCase().includes(searchQuery)) {
        elem
          .closest('[data-filterable-target="container"]')
          .classList
          .remove('visually-hidden');
      } else {
        elem
          .closest('[data-filterable-target="container"]')
          .classList
          .add('visually-hidden');
      }
    });

    if (this.hasSectionTarget) {
      this.sectionTargets.forEach((section) => {
        section.classList.remove('visually-hidden');

        const { children } = section.querySelector('.integration-grid');

        const visibleChildren = Array.from(children).filter((child) => !child.classList.contains('modal') && !child.classList.contains('visually-hidden'));
        if (visibleChildren.length == 0) section.classList.add('visually-hidden');
      });
    }
  }

  clearSearch() {
    this.searchQueryTarget.value = '';
    this.filterData();
  }
}
