/* eslint-disable class-methods-use-this */
/* eslint-disable no-new */
import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

const selectMultipleSettings = {
  plugins: ['no_active_items', 'no_backspace_delete', 'remove_button'],
  allowEmptyOption: true,
  optgroups: [
    { $order: 1, id: '1', name: 'Departments' },
    { $order: 2, id: '2', name: 'Locations' },
    { $order: 3, id: '3', name: 'Legal Entities' },
  ],
  optgroupLabelField: 'name',
  optgroupValueField: 'id',
  lockOptgroupOrder: true,
  render: {
    option(data, escape) {
      return '<div>'
          + `<span class="title text-small">${escape(data.text)}</span>`
          + '</div>';
    },
    item(data, escape) {
      const optgroups = [
        'Department',
        'Location',
        'Legal Entity',
      ];
      const entityName = escape(optgroups[data.optgroup - 1]);
      return '<div><div class="selection">'
        + `<div class="selection__entity">${entityName}</div>`
        + `<div class="selection__name">${escape(data.text)}</div>`
        + '</div></div>';
    },
  },
};

export default class extends Controller {
  static targets = ['select'];

  destroy() {
    const selects = document.querySelectorAll('.tomselected');
    selects.forEach((elem) => {
      if (elem.tomselect !== undefined) elem.tomselect.destroy();
    });
  }

  initialize() {
    this.destroy();
    new TomSelect(this.selectTarget, selectMultipleSettings);
  }

  connect() {
    this.initialize();
  }

  disconnect() {
    this.destroy();
  }
}
