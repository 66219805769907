/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'form',
    'label',
    'counter',
    'input',
    'clearBtn'
  ];

  _addHiddenColumnsCounter() {
    const uncheckedOptionsLength = this.inputTargets.filter((filter) => !filter.checked).length;
    if (uncheckedOptionsLength <= 0) return;

    this.counterTarget.textContent = ` | ${uncheckedOptionsLength} Applied`;
    this._showCounterAndStylize();
  }

  _showCounterAndStylize() {
    this.counterTarget.classList.remove('d-none');
    this.labelTarget.classList.remove('border_sm');
    this.clearBtnTarget.classList.remove('d-none');

    this.labelTarget.classList.add('pe-0');
    this.labelTarget.classList.add('border_none');
    this.labelTarget.classList.add('hover-bg-white');
    this.labelTarget.classList.add('active-bg-white');
    this.clearBtnTarget.classList.add('ps-0');
    this.clearBtnTarget.classList.add('border_none');
    this.clearBtnTarget.classList.add('hover-bg-white');
    this.clearBtnTarget.classList.add('active-bg-white');
  }

  resetColumns(e) {
    e.stopImmediatePropagation();
    this.inputTargets.forEach((input) => input.checked = true);
    this.formTarget.submit();
  }

  hideColumns() {
    this.formTarget.submit();
  }

  connect() {
    this._addHiddenColumnsCounter();
  }
}
