import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['auditAssetForm'];

  save(event) {
    Rails.fire(this.auditAssetFormTarget, 'submit');
  }
}
