/* eslint-disable class-methods-use-this */
/* eslint-disable no-new */
import { Controller } from '@hotwired/stimulus';
import { DateTime, TempusDominus } from '@eonasdan/tempus-dominus';

export default class extends Controller {
  static targets = [
    'typeSelect',
    'dueDateInput',
  ];

  updateDueDate() {
    const typeOption = this.typeSelectTarget.querySelector('option');
    const newDefaultDate = new DateTime(typeOption.dataset.default_due_date);
    this.datepicker.dates.setValue(newDefaultDate);
  }

  connect() {
    this.datepicker = new TempusDominus(this.dueDateInputTarget, {
      display: {
        viewMode: 'calendar',
        theme: 'light',
        components: {
          clock: false,
        },
      },
      localization: {
        locale: 'en-us',
        format: 'dd MMM yyyy',
      },
    });
  }
}
