/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = {
    check: String,
    uncheck: String,
    checkText: String,
    uncheckText: String,
    checkClass: String,
    uncheckClass: String,
    releaseDisable: { type: Boolean, default: true },
    method: { type: String, default: 'PATCH' },
  };

  async remoteChange(e) {
    const checkbox = e.target;
    checkbox.setAttribute('disabled', true);

    const { checked } = checkbox;
    const url = checked ? this.checkValue : this.uncheckValue;
    const labelText = this._labelText(checked);
    const [oldClass, newClass] = this._btnClassToggle(checked);
    const { releaseDisableValue } = this;

    Rails.ajax({
      url,
      type: this.methodValue,
      success(body) {
        Turbo.renderStreamMessage(body);
        if (labelText) {
          checkbox.labels.forEach((el) => {
            if (labelText) el.textContent = labelText;
            if (newClass) el.classList.add(newClass);
            if (oldClass) el.classList.remove(oldClass);
          });
        }
        if (releaseDisableValue) checkbox.removeAttribute('disabled');
      },
      error(data) {
        checkbox.checked = !checkbox.checked;
        checkbox.removeAttribute('disabled');
        console.error('Failed toggle select', data);
        alert('Something went wrong, try again please');
      },
    });
  }

  _labelText(checked) {
    return checked ? this.uncheckTextValue : this.checkTextValue;
  }

  _btnClassToggle(checked) {
    return checked ? [this.checkClassValue, this.uncheckClassValue] : [this.uncheckClassValue, this.checkClassValue];
  }
}
