/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['radioConfirmation', 'input'];

  _showRanking() {
    this.inputTargets.forEach((e) => e.classList.remove('d-none'));
  }

  _hideRanking() {
    this.inputTargets.forEach((e) => e.classList.add('d-none'));
  }

  toggleInput(e) {
    const isChosen = e.target.checked;
    const isShown = e.target.value === 'yes';
    if (isShown && isChosen) {
      this._showRanking();
      return;
    }

    this._hideRanking();
  }

  connect() {
    const target = { target: this.radioConfirmationTarget };
    this.toggleInput(target);
  }
}
