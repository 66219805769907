/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const modal = new bootstrap.Modal(this.element);
    modal.show();
  }

  get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='turbo-modal']");
  }

  _hideModal() {
    // NOTE: Prevents re-fetching the content based on the src attribute
    // For details see: https://labs.k.io/lessons-learned-with-hotwire/
    this.modalTurboFrame.removeAttribute('src');

    const modal = bootstrap.Modal.getInstance(this.element);
    modal.hide();
    this.element.remove();
  }

  submitEnd(e) {
    if (e.detail.success) this._hideModal();
  }
}
