/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import { Controller } from '@hotwired/stimulus';
import { I18n } from 'i18n-js';
import translations from '../locales/translations.json';
import { post } from '../shared/request';

const i18n = new I18n(translations);

// const debug = (...args) => console.log('[bulk-actionable]', ...args);

export default class extends Controller {
  static targets = [
    'mainCheckbox',
    'hideWhenSelected',
    'showWhenSelected',
    'itemCheckbox',
    'translatable',
    'selectAllPanel',
  ];

  static values = {
    selectedItems: Array,
    itemsCount: Number,
    idParam: { type: String, default: 'uuid' },
    controllerPath: String,
    category: String,
  };

  // lifecycle callbacks

  connect() {
    if (!this.hasMainCheckboxTarget) return;

    this.#renderUpdates(this.selectedItemsValue);
  }

  mainCheckboxTargetConnected() {
    this.selectedItems = JSON.parse(this.mainCheckboxTarget.dataset.selectedItems);
    this.#renderUpdates(this.selectedItemsValue);
  }

  // action handlers

  async toggleSelectAll({ target: { checked }, target: checkbox }) {
    try {
      const { selected } = await post(`${this.requestUrlBasePath}/bulk_action_${checked ? 'check' : 'uncheck'}_all${this.requestUrlQuery}`);
      this.selectedItems = selected;
    } catch (error) {
      console.error('[bulk-actionable]', 'Failed toggle select all', error);
      alert('Something went wrong, try again please');
      checkbox.checked = !checked;
    }

    Turbo.visit(location.href, { action: 'replace' });
  }

  async toggleSelectPage({ target: { checked } }) {
    this.itemCheckboxTargets.forEach((checkbox) => { checkbox.checked = checked; });

    const newItems = checked
      ? [...this.selectedItemsValue, ...this.currentPageIds]
      : this.selectedItemsValue.filter(
        (item) => !this.currentPageIds.includes(item),
      );

    this.#renderUpdates(newItems);

    try {
      await this.#setSelection(this.currentPageIds, checked);
    } catch {
      this.itemCheckboxTargets.forEach((checkbox) => { checkbox.checked = !checked; });
    }
  }

  async toggleSelect({ target: checkbox }) {
    const { id } = checkbox.dataset;
    const newItems = checkbox.checked
      ? [...this.selectedItemsValue, id]
      : this.selectedItemsValue.filter((item) => item !== id);
    this.#renderUpdates(newItems);

    try {
      await this.#setSelection([id], checkbox.checked);
    } catch {
      checkbox.checked = !checkbox.checked;
    }
  }

  async clearSelection() {
    const oldSelectedItems = this.selectedItemsValue;
    this.selectedItems = [];
    this.itemCheckboxTargets.forEach((checkbox) => { checkbox.checked = false; });
    this.#renderUpdates(this.selectedItemsValue);

    try {
      const { selected } = await post(`${this.requestUrlBasePath}/bulk_action_uncheck_all${this.requestUrlQuery}`);
      this.selectedItems = selected;
    } catch (error) {
      this.selectedItems = oldSelectedItems;
      this.#renderUpdates(oldSelectedItems);
      console.error('[bulk-actionable]', 'Failed clear selection', error);
      alert('Something went wrong, try again please');
    }
  }

  async selectAll() {
    const oldSelectedItems = this.selectedItemsValue;
    this.selectedItems = [...this.currentPageIds, ...oldSelectedItems];
    this.itemCheckboxTargets.forEach((checkbox) => { checkbox.checked = true; });
    this.#renderUpdates(this.selectedItemsValue);

    try {
      const currentFilter = new URLSearchParams(location.search);
      new URLSearchParams(this.requestUrlQuery).forEach((value, key) => {
        currentFilter.append(key, value);
      });
      const mergedQuery = `?${currentFilter.toString()}`;
      const { selected } = await post(`${this.requestUrlBasePath}/bulk_action_check_all${mergedQuery}`);
      this.selectedItems = selected;
      this.#renderUpdates(this.selectedItemsValue);
    } catch (error) {
      this.selectedItems = oldSelectedItems;
      this.#renderUpdates(oldSelectedItems);
      console.error('[bulk-actionable]', 'Failed select all', error);
      alert('Something went wrong, try again please');
    }
  }

  // getters and setters

  get requestUrlQuery() {
    return this.hasCategoryValue ? `?category=${this.categoryValue}` : '';
  }

  get requestUrlBasePath() {
    return this.controllerPathValue || location.pathname;
  }

  set selectedItems(newValue) {
    if (!Array.isArray(newValue)) throw new Error('selectedItems must be an array');

    this.selectedItemsValue = newValue.map((item) => item.toString());
  }

  get currentPageIds() {
    return this.itemCheckboxTargets.map((item) => item.dataset.id);
  }

  // private methods

  async #setSelection(ids, checked) {
    const remoteAction = checked ? 'check' : 'uncheck';

    try {
      const { selected } = await post(
        `${this.requestUrlBasePath}/bulk_action_${remoteAction}${this.requestUrlQuery}`,
        { [this.idParamValue]: ids },
      );

      this.selectedItems = selected;
    } catch (error) {
      this.#renderUpdates(this.selectedItemsValue);
      console.error('[bulk-actionable]', `Failed toggle select id: ${id}`, error);
      alert('Something went wrong, try again please');

      throw error;
    }
  }

  #renderUpdates(newItems) {
    newItems = [...new Set(newItems)];
    const count = newItems.length;

    if (count > 0 && count < this.itemsCountValue) {
      this.selectAllPanelTarget.classList.remove('d-none');
    } else {
      this.selectAllPanelTarget.classList.add('d-none');
    }

    if (count <= 0) {
      // When no items are selected
      this.hideWhenSelectedTargets.forEach((target) => target.classList.remove('d-none'));
      this.showWhenSelectedTargets.forEach((target) => target.classList.add('d-none'));
    } else {
      // When some items are selected
      this.hideWhenSelectedTargets.forEach((target) => target.classList.add('d-none'));
      this.showWhenSelectedTargets.forEach((target) => target.classList.remove('d-none'));
    }

    // When all items from page are selected
    if (newItems.length > 0) {
      this.mainCheckboxTarget.checked = this.currentPageIds.every(
        (e) => newItems.includes(e),
      );
    } else {
      this.mainCheckboxTarget.checked = false;
    }

    // Update translations

    this.translatableTargets.filter((e) => e.dataset.i18nKey).forEach((element) => {
      const { dataset: { i18nKey } } = element;
      const text = i18n.t(i18nKey, { count });
      element.innerHTML = text;
    });
  }
}
