/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'assetType',
    'photo',
  ];

  static values = {
    withPhotoRequiredTypes: Array,
  };

  _showFields(fields) {
    fields.forEach((field) => field.classList.remove('d-none'));
  }

  _hideFields(fields) {
    fields.forEach((field) => field.classList.add('d-none'));
  }

  _togglePhotos() {
    const select = this.assetTypeTarget;
    const selectedOption = select.options[select.selectedIndex].text;
    const isPhotoRequiredForSelectedType = this.withPhotoRequiredTypesValue.includes(selectedOption);
    const isPhotoRequiredForAllTypes = this.withPhotoRequiredTypesValue.length == 0;

    if (isPhotoRequiredForSelectedType || isPhotoRequiredForAllTypes) {
      this._showFields(this.photoTargets);
    } else {
      this._hideFields(this.photoTargets);
    }
  }

  updateTemplate() {
    if (this.hasPhotoTarget) this._togglePhotos();
  }

  connect() {
    this.updateTemplate();
  }
}
