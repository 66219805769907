/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from '@rails/activestorage';
import * as FilePond from 'filepond';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

export default class extends Controller {
  static targets = [
    'filePond',
    'editLink',
    'submitBtn',
    'removeBtn',
    'fileInput',
  ];

  connect() {
    if (this.hasFilePondTarget) {
      this.setupFilePond();
    }
  }

  setupFilePond() {
    const uploadUrl = document.querySelector("meta[name='direct-upload-url']")
      .content;

    FilePond.registerPlugin(
      FilePondPluginFileValidateType,
      FilePondPluginImageExifOrientation,
      FilePondPluginImagePreview,
      FilePondPluginImageCrop,
      FilePondPluginImageResize,
      FilePondPluginImageTransform,
      FilePondPluginFilePoster,
    );

    FilePond.setOptions({
      allowRevert: false,
      server: {
        url: uploadUrl,
        process: this.fileProcess,
      },
    });

    FilePond.create(this.filePondTarget, {
      imagePreviewHeight: 130,
      imageCropAspectRatio: '1:1',
      imageResizeTargetWidth: 150,
      imageResizeTargetHeight: 150,
      stylePanelLayout: 'compact circle',
      styleLoadIndicatorPosition: 'center bottom',
      styleButtonRemoveItemPosition: 'center bottom',
      labelIdle: 'Add image',
      acceptedFileTypes: ['image/png', 'image/jpg', 'image/jpeg'],
      labelFileTypeNotAllowed: 'File of invalid type',
    });

    const pond = document.querySelector('.filepond--root');
    pond.addEventListener('FilePond:addfile', (e) => {
      this.submitBtnTarget.disabled = true;
    });

    pond.addEventListener('FilePond:processfile', (e) => {
      this.submitBtnTarget.disabled = false;
    });

    pond.addEventListener('FilePond:removefile', (e) => {
      this.submitBtnTarget.disabled = false;
    });
  }

  fileProcess(
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options,
  ) {
    const uploadUrl = document.querySelector("meta[name='direct-upload-url']").content;
    const upload = new DirectUpload(file, uploadUrl);

    upload.create((uploadError, blob) => {
      if (uploadError) {
        error();
      }

      load(blob.signed_id);
    });
  }
}
