/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectionRadio', 'selectionInput'];

  updateRadio() {
    if (this.selectionRadioTarget.checked) {
      this.selectionInputTargets.forEach((elem) => elem.disabled = false);
    } else {
      this.selectionInputTargets.forEach((elem) => elem.disabled = true);
    }
  }

  connect() {
    this.updateRadio();
  }
}
