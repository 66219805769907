import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['collapsibleContainer'];

  onChange(e) {
    this.collapsibleContainerTargets.forEach((elem) => elem.classList.remove('show'));
    this.collapsibleContainerTargets.filter(
      (elem) => elem.id == e.target.value
    ).map((elem) => elem.classList.add('show'));
  }
}
