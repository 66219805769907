import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['selectDevice', 'expandable'];

  openSelect() {
    this.expandableTarget.classList.remove('d-none');
    const bs_dropdown = new bootstrap.Dropdown(this.selectDeviceTarget);
    setTimeout(() => bs_dropdown.show(), 0);
  }

  installLicense(e) {
    const element = e.currentTarget;
    const license_uuid = element.getAttribute('data-license-uuid')
    const asset_id = document.getElementById('device_id').value;

    if (asset_id) {
      Rails.ajax({
        url: `/licenses/${license_uuid}/assets/${asset_id}/install`,
        type: 'PUT',
        success(data) {},
        error(data) {
          alert(data.responseText);
        },
      });
    }
  }
}
