import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { prefill: String };

  static targets = [
    'sourceInput',
    'destinationInput',
    'link',
  ];

  addValueToPrefillParam() {
    if (!this.hasLinkTarget) return;

    const { value } = this.sourceInputTarget;
    const { href } = this.linkTarget;
    const url = new URL(href);
    url.searchParams.set('prefill', value);
    this.linkTarget.href = url.toString();
  }

  setPrefillValue() {
    if (this.destinationInputTarget.value.length > 0) return;

    this.destinationInputTarget.value = this.prefillValue;
  }

  connect() {
    if (!this.hasDestinationInputTarget) return;

    this.setPrefillValue();
  }
}
