import { Controller } from '@hotwired/stimulus';

let windowObjectReference = null;
let previousUrl = null;

// NOTE: https://dev.to/dinkydani21/how-we-use-a-popup-for-google-and-outlook-oauth-oci
const openSignInWindow = (url, name) => {
  // window features
  const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
    or if such pointer exists but the window was closed */
    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
    then we load it in the already opened secondary window and then
    we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference.focus();
  } else {
    /* else the window reference must exist and the window
    is not closed; therefore, we can bring it back on top of any other
    window with the focus() method. There would be no need to re-create
    the window or to reload the referenced resource. */
    windowObjectReference.focus();
  }

  previousUrl = url;
};

export default class extends Controller {
  static values = {
    url: String,
  };

  static targets = [
    'button',
    'form',
  ];

  popup() {
    openSignInWindow(this.urlValue, 'popup');
  }

  connect() {
    if (!this.hasButtonTarget && !this.hasFormTarget) return;

    const element = this.hasFormTarget ? this.formTarget : this.buttonTarget;

    // NOTE: Intentionally comparing with a string
    // form_tag in app/views/web/sso/popup.html.haml coerces boolean attribute to string
    if (element.dataset.autoClick !== 'true') return;

    if (this.hasFormTarget) {
      element.submit();
    } else {
      element.click();
    }
  }
}
