/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import { I18n } from 'i18n-js';
import translations from '../../locales/translations.json';

const i18n = new I18n(translations);

export default class extends Controller {
  static targets = [
    'checkbox',
    'bulkActionControl',
    'actionControl',
    'bulkActionArchiveButton',
    'selectAllLegalEntitiesPanel',
    'selectAllSelectedLegalEntitiesCountText'
  ];

  static values = {
    selectedLegalEntitiesCount: Number,
    allLegalEntitiesCount: Number,
    tickPath: String,
    untickPath: String,
  };

  async _submit_legal_entity_ids(legal_entity_ids, path) {
    try {
      const response = await fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify({ legal_entity_ids }),
      });

      if (!response.ok) {
        alert('Something went wrong. Please contact support.');
        return;
      }

      const body = await response.json();
      this.selectedLegalEntitiesCountValue = body.legal_entity_ids_count;
      this._toggleControls();
    } catch (error) {
      alert('Something went wrong. Please contact support.');
    }
  }

  _showSelectAllLegalEntitiesPanel() {
    this.selectAllLegalEntitiesPanelTarget.classList.remove('d-none');
  }

  _hideSelectAllLegalEntitiesPanel() {
    this.selectAllLegalEntitiesPanelTarget.classList.add('d-none');
  }

  bulkCheckboxChanged(event) {
    const state = event.target.checked;
    const legal_entity_ids = [];
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = state;
      legal_entity_ids.push(checkbox.value);
    });
    const path = (state == true ? this.tickPathValue : this.untickPathValue);
  
    this._submit_legal_entity_ids(legal_entity_ids, path).then(() => {
      if (this.selectedLegalEntitiesCountValue === 0) {
        this._hideSelectAllLegalEntitiesPanel();
        event.target.checked = false;
        return;
      }
  
      if (this.selectedLegalEntitiesCountValue < 20) {
        this._hideSelectAllLegalEntitiesPanel();
        return;
      }
  
      this._toggleControls();
      if (state == true) {
        this._showSelectAllLegalEntitiesPanel();
      } else {
        this._hideSelectAllLegalEntitiesPanel();
      }
    });
  }

  checkboxChanged(event) {
    const state = event.target.checked;
    const legal_entity_id = event.target.value;
    const path = (state == true ? this.tickPathValue : this.untickPathValue);
    this._submit_legal_entity_ids([legal_entity_id], path);
    this._hideSelectAllLegalEntitiesPanel();
  }

  _updateBulkControls() {
    const selectedCount = this.selectedLegalEntitiesCountValue;
    this.selectAllSelectedLegalEntitiesCountTextTarget.innerHTML = i18n.t('web.companies.legal_entities.bulk_action_progress_panel.select_all_selected_html', { selected_legal_entities_count: selectedCount });
    this.bulkActionArchiveButtonTarget.innerHTML = i18n.t('web.companies.legal_entities.bulk_action_buttons.bulk_archive', { count: selectedCount });
  }

  _toggleCardControls() {
    const hideCardControls = this.selectedLegalEntitiesCountValue > 0;
    if (hideCardControls) {
      this.actionControlTargets.forEach((control) => {
        control.classList.add(control.dataset.hideClass);
      });
      return;
    }

    this.actionControlTargets.forEach((control) => {
      control.classList.remove(control.dataset.hideClass);
    });
  }

  _toggleBulkControls() {
    if (this.selectedLegalEntitiesCountValue > 0) {
      this.bulkActionControlTargets.forEach((control) => {
        control.classList.remove(control.dataset.hideClass);
      });
      return;
    }

    this.bulkActionControlTargets.forEach((control) => {
      control.classList.add(control.dataset.hideClass);
    });
  }

  _toggleControls() {
    this._updateBulkControls();
    this._toggleBulkControls();
    this._toggleCardControls();
  }

  connect() {
    this._toggleControls();
  }
}
