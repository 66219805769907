/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  _collapseExceptAnchor() {
    const targetHeader = document.getElementById(this.anchorId);
    const targetCollapsible = targetHeader.querySelector('a');
    if (targetCollapsible === undefined) return;

    const anchoredContainerId = targetCollapsible.attributes.href.value.slice(1);
    const collapseElementList = [].slice.call(document.querySelectorAll('#turbo-offcanvas-container .collapse'));
    collapseElementList.filter((element) => element.id !== anchoredContainerId)
      .map((element) => new bootstrap.Collapse(element));
    this.dispatch('collapsed', { detail: { content: anchoredContainerId } });
  }

  setTargetId(e) {
    const { href } = e.target.closest('a');
    const url = new URL(href);
    const params = new URLSearchParams(url.search);
    const inputId = params.get('input_id');
    this.targetId = inputId;
    [, this.anchorId] = href.split('#');
  }

  scrollToAnchor() {
    if (this.anchorId === undefined) return;

    this._collapseExceptAnchor();
    document.getElementById(this.anchorId).scrollIntoView();
    delete this.anchorId;
  }

  focusAtInput() {
    if (this.targetId === undefined) return;

    const fieldToFocus = document.querySelector(`[id=${this.targetId}]`) || document.querySelector(`[id^=${this.targetId}]`);
    if (fieldToFocus === null) return;

    const remoteSelectToFocus = fieldToFocus.closest('.remote_collection_select');
    if (remoteSelectToFocus !== null) {
      remoteSelectToFocus.querySelector('.remote_collection_select__wrapper').click();
      remoteSelectToFocus.querySelector('.remote_collection_select__search').focus();
      return;
    }

    if ((fieldToFocus instanceof HTMLInputElement) || (fieldToFocus instanceof HTMLTextAreaElement)) {
      fieldToFocus.focus();
      return;
    }

    const tomSelectToFocus = fieldToFocus.parentElement.querySelector('.ts-wrapper');
    if (tomSelectToFocus !== null) {
      tomSelectToFocus.querySelector('.ts-control').click();
      const tomSelectDropdown = tomSelectToFocus.querySelector('.dropdown-input');
      if (tomSelectDropdown !== null) {
        tomSelectToFocus.querySelector('.dropdown-input').focus();
      }
      return;
    }

    fieldToFocus.click();
    const bs_dropdown = bootstrap.Dropdown.getOrCreateInstance(fieldToFocus);
    bs_dropdown.update();
  }
}
