/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';
import autosize from 'autosize';

export default class extends Controller {
  autosizeUp(e) {
    autosize(e.target);
  }

  autosizeDown(e) {
    autosize.destroy(e.target);
  }
}
