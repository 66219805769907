import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['spinner', 'submitBtn'];

  showSpinner() {
    this.spinnerTarget.classList.remove('d-none');
    if (this.hasSubmitBtnTarget) {
      this.submitBtnTarget.classList.add('disabled');
    }
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('d-none');
  }
}
