/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: 'AccountingAccountsLoaderSpinnerChannel', id: this.data.get('id') },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      },
    );
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    if (data.completed) {
      this._reloadPage();
    }
  }

  _reloadPage() {
    Turbo.visit(window.location.pathname, { action: 'replace' });
  }
}
