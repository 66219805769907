/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['short', 'long', 'showMore', 'showLess'];

  toggleShowContainer(e) {
    e.preventDefault();
    this.longTarget.classList.toggle('d-none');
    this.shortTarget.classList.toggle('d-none');
  }

  showMoreTargetConnected(element) {
    element.addEventListener('click', this.toggleShowContainer.bind(this));
  }

  showLessTargetConnected(element) {
    element.addEventListener('click', this.toggleShowContainer.bind(this));
  }
}
