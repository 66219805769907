/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
// NOTE: This is added to imitate submitting of the form only
// To be removed after we refactor User Permissions and Asset Types settings
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submitBtn'];

  _disableButton() {
    const button = this.submitBtnTarget;
    const disableWith = button.getAttribute('data-disable-with');
    button.innerHTML = disableWith;
    button.disabled = true;
  }

  _enableButton() {
    const button = this.submitBtnTarget;
    const actionText = button.getAttribute('data-action-text');
    button.innerHTML = actionText;
    button.disabled = false;
  }

  imitateSubmit() {
    this._disableButton();
    setTimeout(this._enableButton.bind(this), 500);
  }
}
