import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['setNextInspection', 'nextDueDate', 'notes', 'submit'];

  switchVisibility() {
    if (this.setNextInspectionTarget.checked) {
      this.nextDueDateTarget.classList.remove('hidden', 'd-none');
    } else {
      this.nextDueDateTarget.classList.add('hidden', 'd-none');
    }
  }

  toggleNotesRequired(e) {
    if (e.target.value === 'failed' && !this.notesTarget.value) {
      this.submitTarget.disabled = true;
      this.notesTarget.placeholder = this.notesTarget.dataset.placeholderRequired;
      this.notesTarget.classList.add('border-danger');
    } else {
      this.submitTarget.disabled = false;
      this.notesTarget.placeholder = this.notesTarget.dataset.placeholder;
      this.notesTarget.classList.remove('border-danger');
    }
  }

  toggleSubmit(e) {
    if (e.target.value) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }
}
