/* eslint-disable class-methods-use-this */
// See
// https://medium.com/@kosovacsedad/how-to-turbo-5-scroll-position-persistence-6e4435a60b2e
// https://github.com/turbolinks/turbolinks/issues/181#issuecomment-727549337

import { Controller } from '@hotwired/stimulus';

window.ScrollStore = {};

export default class extends Controller {
  setScrollPosition() {
    window.ScrollStore.top = window.scrollY;
  }

  scroll() {
    if (window.ScrollStore.top) {
      window.scroll(0, window.ScrollStore.top);
    }

    window.ScrollStore = {};
  }

  connect() {
    setTimeout(this.scroll, 0);
  }

  disconnect() {
    document.scrollingElement.style.scrollBehavior = 'unset';
  }
}
