import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'surveyForm',
  ];

  save() {
    this.surveyFormTarget.requestSubmit();
  }
}
