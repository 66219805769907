import { Controller } from '@hotwired/stimulus';

// Usage:
// <div data-controller="select-tabs">
//   <select data-action="select-tabs#showTab">
//     <option value="first">First</option>
//     <option value="any-other-name">Second</option>
//   </select>

//   <div data-select-tabs-target="tab" data-tab-for-value="first">
//     First tab content
//   </div>
//
//   <div data-select-tabs-target="tab" data-tab-for-value="any-other-name">
//     Second tab content
//   </div>
// </div>

export default class extends Controller {
  static targets = ['tab'];

  connect() {
    const { value } = this.element.querySelector('select');
    this.showTab({ target: { value } });
  }

  showTab({ target: { value } }) {
    this.tabTargets.forEach((tab) => {
      if (tab.dataset.tabForValue === value) {
        tab.classList.remove('d-none');
      } else {
        tab.classList.add('d-none');
      }
    });
  }
}
