/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  _addBadge() {
    const badge = document.createElement('span');
    badge.classList.add('badge', 'badge-input_success');
    badge.innerHTML = 'Edited';
    this.element.classList.add('container-with-badge');
    this.element.append(badge);
  }

  _removeBadge() {
    const badge = this.element.querySelector('.badge');
    badge.remove();
  }

  markAsEdited(e) {
    if (e.target.value.length > 0) {
      const hasBadge = this.element.querySelector('.badge') !== null;
      if (!hasBadge) this._addBadge();
      return;
    }

    this._removeBadge();
  }
}
