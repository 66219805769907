/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import { I18n } from 'i18n-js';
import translations from '../../locales/translations.json';

const i18n = new I18n(translations);

export default class extends Controller {
  static targets = [
    'checkbox',
    'bulkActionControl',
    'actionControl',
    'bulkActionArchiveButton',
    'selectAllSublocationsPanel',
    'selectAllSelectedSublocationsCountText'
  ];

  static values = {
    selectedSublocationsCount: Number,
    allSublocationsCount: Number,
    tickPath: String,
    untickPath: String,
  };

  async _submit_sublocation_ids(sublocation_ids, path) {
    try {
      const response = await fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify({ sublocation_ids }),
      });

      if (!response.ok) {
        alert('Something went wrong. Please contact support.');
        return;
      }

      const body = await response.json();
      this.selectedSublocationsCountValue = body.sublocation_ids_count;
      this._toggleControls();
    } catch (error) {
      alert('Something went wrong. Please contact support.');
    }
  }

  _showSelectAllSublocationsPanel() {
    this.selectAllSublocationsPanelTarget.classList.remove('d-none');
  }

  _hideSelectAllSublocationsPanel() {
    this.selectAllSublocationsPanelTarget.classList.add('d-none');
  }

  bulkCheckboxChanged(event) {
    const state = event.target.checked;
    const sublocation_ids = [];
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = state;
      sublocation_ids.push(checkbox.value);
    });
    const path = (state == true ? this.tickPathValue : this.untickPathValue);
  
    this._submit_sublocation_ids(sublocation_ids, path).then(() => {
      if (this.selectedSublocationsCountValue === 0) {
        this._hideSelectAllSublocationsPanel();
        event.target.checked = false;
        return;
      }
  
      if (this.selectedSublocationsCountValue < 20) {
        this._hideSelectAllSublocationsPanel();
        return;
      }
  
      this._toggleControls();
      if (state == true) {
        this._showSelectAllSublocationsPanel();
      } else {
        this._hideSelectAllSublocationsPanel();
      }
    });
  }

  checkboxChanged(event) {
    const state = event.target.checked;
    const sublocation_id = event.target.value;
    const path = (state == true ? this.tickPathValue : this.untickPathValue);
    this._submit_sublocation_ids([sublocation_id], path);
    this._hideSelectAllSublocationsPanel();
  }

  _updateBulkControls() {
    const selectedCount = this.selectedSublocationsCountValue;
    this.selectAllSelectedSublocationsCountTextTarget.innerHTML = i18n.t('web.companies.sublocations.bulk_action_progress_panel.select_all_selected_html', { selected_sublocations_count: selectedCount });
    this.bulkActionArchiveButtonTarget.innerHTML = i18n.t('web.companies.sublocations.bulk_action_buttons.bulk_archive', { count: selectedCount });
  }

  _toggleCardControls() {
    const hideCardControls = this.selectedSublocationsCountValue > 0;
    if (hideCardControls) {
      this.actionControlTargets.forEach((control) => {
        control.classList.add(control.dataset.hideClass);
      });
      return;
    }

    this.actionControlTargets.forEach((control) => {
      control.classList.remove(control.dataset.hideClass);
    });
  }

  _toggleBulkControls() {
    if (this.selectedSublocationsCountValue > 0) {
      this.bulkActionControlTargets.forEach((control) => {
        control.classList.remove(control.dataset.hideClass);
      });
      return;
    }

    this.bulkActionControlTargets.forEach((control) => {
      control.classList.add(control.dataset.hideClass);
    });
  }

  _toggleControls() {
    this._updateBulkControls();
    this._toggleBulkControls();
    this._toggleCardControls();
  }

  connect() {
    this._toggleControls();
  }
}
