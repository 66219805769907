import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'quantityInput',
    'submitBtn',
  ];

  refreshQuantity() {
    const quantity = this.quantityInputTarget.value;
    const button = this.submitBtnTarget;
    button.value = `Create ${quantity} assets`;
  }
}
