import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['from', 'to'];

  connect() {
    const { TempusDominus, Namespace } = global.tempusDominius;

    const options = {
      localization: {
        locale: 'en-us',
        format: 'yyyy-MM-dd HH:mm',
      },
    };
    const fromPicker = new TempusDominus(this.fromTarget, options);
    const toPicker = new TempusDominus(this.toTarget, options);

    this.subscription = fromPicker.subscribe(Namespace.events.change, (e) => {
      toPicker.updateOptions({
        restrictions: {
          minDate: e.date,
        },
      });
    });
  }

  disconnect() {
    this.subscription?.unsubscribe();
  }
}
