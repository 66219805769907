/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

const settings = {
  selectNoSearch: {
    controlInput: null,
    maxOptions: null,
    allowEmptyOption: true,
  },
  selectWithIcon: {
    allowEmptyOption: true,
    controlInput: null,
    maxOptions: null,
    render: {
      option(data, escape) {
        const isImageMissing = data.image === undefined || data.image.length === 0;
        if (isImageMissing) return `<div>${data.text}</div>`;

        return `<div><img class='me-2' width='18px' src='${data.image}'>${data.text}</div>`;
      },
      item(item, escape) {
        const isImageMissing = item.image === undefined || item.image.length === 0;
        if (isImageMissing) return `<div>${item.text}</div>`;

        return `<div><img class='me-2' width='18px' src='${item.image}'>${item.text}</div>`;
      },
    },
  },
  selectWithIconAndSearch: {
    allowEmptyOption: true,
    controlInput: null,
    maxOptions: null,
    plugins: ['dropdown_input'],
    render: {
      option(data, escape) {
        const isImageMissing = data.image === undefined || data.image.length === 0;
        if (isImageMissing) return `<div>${data.text}</div>`;

        return `<div><img class='me-2' width='18px' src='${data.image}'>${data.text}</div>`;
      },
      item(item, escape) {
        const isImageMissing = item.image === undefined || item.image.length === 0;
        if (isImageMissing) return `<div>${item.text}</div>`;

        return `<div><img class='me-2' width='18px' src='${item.image}'>${item.text}</div>`;
      },
    },
  },
  selectWithBootstrapIcon: {
    allowEmptyOption: true,
    controlInput: null,
    maxOptions: null,
    render: {
      option(data, escape) {
        const row = document.createElement('div');
        row.classList.add('row');
        if (data.bi) {
          const icon = document.createElement('div');
          icon.classList.add('col-1', 'px-0', 'text-center');
          const i = document.createElement('i');
          i.classList.add(...data.bi.split(' '));
          icon.appendChild(i);
          row.appendChild(icon);
        }
        const text = document.createElement('div');
        text.classList.add('col-11', 'ps-1', 'pe-0');
        text.innerHTML = data.text;
        row.appendChild(text);
        return row;
      },
      item(item, escape) {
        const row = document.createElement('div');
        row.classList.add('row', 'w-100');
        if (item.bi) {
          const icon = document.createElement('div');
          icon.classList.add('col-1', 'px-0', 'text-center');
          const i = document.createElement('i');
          i.classList.add(...item.bi.split(' '));
          icon.appendChild(i);
          row.appendChild(icon);
        }
        const text = document.createElement('div');
        text.classList.add('col-11', 'ps-1', 'pe-0');
        text.innerHTML = item.text;
        row.appendChild(text);
        return row;
      },
    },
  },
};

export default class extends Controller {
  static values = {
    type: String,
  };

  _initTomSelect() {
    if (this.element) {
      const type = this.typeValue.length !== 0 ? this.typeValue : 'selectNoSearch';
      this.select = new TomSelect(this.element, settings[type]);
    }
  }

  connect() {
    this._initTomSelect();
  }

  disconnect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}
