/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'requireCheckbox',
    'settingInput',
  ];

  togglePhotoTypeSelection() {
    const isPhotoRequired = this.requireCheckboxTargets.filter((checkbox) => checkbox.checked).length > 0;
    this.settingInputTargets.forEach((input) => {
      // NOTE: Decided not to use disabled attribute, otherwise checkboxes are reset when submitted
      // input.disabled = !isPhotoRequired;
      input.classList.toggle('disabled', !isPhotoRequired);
    });
    const checkboxHeaders = this.element.querySelectorAll('.form-group__header-controllable');
    checkboxHeaders.forEach((header) => {
      header.classList.toggle('disabled', !isPhotoRequired);
    });
    const remoteSelectDropdownToggle = this.element.querySelector('.remote_collection_select__wrapper');
    remoteSelectDropdownToggle.classList.toggle('disabled', !isPhotoRequired);
  }

  connect() {
    this.togglePhotoTypeSelection();
  }
}
