/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'label'];
  static values = {
    onChangeLabel: String,
  }

  onChange() {
    this._disableInput();
    this._setLabel(this.onChangeLabelValue);
  }

  _setLabel(value) {
    this.labelTarget.innerHTML = value;
  }

  _disableInput() {
    this.inputTarget.setAttribute('disabled', true);
  }
}
