import { Controller } from '@hotwired/stimulus';

// Usage:
// <div data-controller="radio-tabs">
//   <input type="radio" name="tab" value="first" data-action="click->radio-tabs#showTab">
//   <input type="radio" name="tab" value="any-other-name" data-action="click->radio-tabs#showTab">
//
//   <div data-radio-tabs-target="tab" data-tab-for-value="first">
//     First tab content
//   </div>
//
//   <div data-radio-tabs-target="tab" data-tab-for-value="any-other-name">
//     Second tab content
//   </div>
// </div>

export default class extends Controller {
  static targets = ['tab'];

  connect() {
    const checked = this.element.querySelector('input[type="radio"]:checked');
    const value = checked ? checked.value : this.element.querySelector('input[type="radio"]').value;
    this.showTab({ target: { value } });
  }

  showTab({ target: { value } }) {
    this.tabTargets.forEach((tab) => {
      if (tab.dataset.tabForValue === value) {
        tab.classList.remove('d-none');
      } else {
        tab.classList.add('d-none');
      }
    });
  }
}
