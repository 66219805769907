/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

// TODO: Refactor this hardcoded list of device types with IMEI
// NOTE: Intentional snake case naming here and below
const imei_types = [
  'Mobile Phones and Cell Phones',
  'Tablets and E-Readers',
  'Adapters and Dongles',
  'Wireless Routers',
];

const computer_name_types = [
  'All in one Computers',
  'Desktops and Computer Towers',
  'Laptops',
  'Single Board Mini Computers',
  'Tablets and E-Readers',
  'Smart Whiteboards',
  'Television Displays',
  'Franking Machines',
  'GPS Units',
  'Network Attached Storage (NAS)',
  'Routers',
  'Wireless Routers',
  'Game Development Kits',
  'Games Consoles',
  'IoT Devices',
  'Card Readers and Payment Systems',
  'Multifunction Printers',
  'Photocopiers',
  'Printers',
  'Scanners',
  'CCTV and Dash Cameras',
  'Conference Phones',
  'Desk Phones',
  'Mobile Phones and Cell Phones',
  'Virtual Reality Headsets',
  'Watches and Sensors',
  'Gateways',
  'Access Points',
  'Network Switches',
  'Point-to-Point Stations',
  'Wireless Gateways',
  'Wireless Access Points',
  'Wireless Bridges',
  'Video Conferencing Computers',
  'Thin Clients',
];

const phone_types = [
  'Mobile Phones and Cell Phones',
  'Conference Phones',
  'Desk Phones',
  'Tablets and E-Readers',
  'Sim Cards',
  'PBXs',
];

const storage_size_types = [
  'All in one Computers',
  'Desktops and Computer Towers',
  'Laptops',
  'Single Board Mini Computers',
  'Network Attached Storage (NAS)',
  'Games Consoles',
  'USB Devices and Drives',
  'RAM',
  'Memory Cards',
  'Canister',
  'Virtual Machines (VMs)',
  'Cloud Servers',
  'Containers',
  'Workstations',
  'Power Banks and UPSs',
  'Keys and Access Passes',
  'Refrigerators',
  'Tablets and E-Readers',
  'Bags, Cases and Covers',
  'Server Cabinets',
  'Video Conferencing Computers',
  'Cupboards and Cabinets',
  'Rainwater Harvesters',
  'Bee Hives',
  'Packaging',
  'Mobile Phones and Cell Phones',
  'Hard Disk Drives (HDDs and SSDs)',
];

const has_keyboard_types = [
  'All in one Computers',
  'Laptops',
  'Keyboards',
  'Thin Clients',
];

const mac_types = [
  'Access Points',
  'All in one Computers',
  'CCTV and Dash Cameras',
  'Card Readers and Payment Systems',
  'Conference Phones',
  'Desk Phones',
  'Desktops and Computer Towers',
  'Franking Machines',
  'GPS Units',
  'Game Development Kits',
  'Games Consoles',
  'Gateways',
  'IoT Devices',
  'Laptops',
  'Mobile Phones and Cell Phones',
  'Multifunction Printers',
  'Network Attached Storage (NAS)',
  'Network Switches',
  'Photocopiers',
  'Point-to-Point Stations',
  'Printers',
  'Routers',
  'Scanners',
  'Servers',
  'Single Board Mini Computers',
  'Smart Whiteboards',
  'Storage Area Networks (SAN)',
  'Tablets and E-Readers',
  'Television Displays',
  'Thin Clients',
  'Video Conferencing Computers',
  'Virtual Reality Headsets',
  'Watches and Sensors',
  'Wireless Access Points',
  'Wireless Bridges',
  'Wireless Gateways',
  'Wireless Routers',
];

export default class extends Controller {
  static targets = [
    'assetType',
    'imeiField',
    'computerRelatedField',
    'phoneNumberField',
    'storageSizeField',
    'keyboardLayoutField',
    'macField'
  ];

  _showFields(fields) {
    fields.forEach((field) => field.classList.remove('d-none'));
  }

  _hideFields(fields) {
    fields.forEach((field) => field.classList.add('d-none'));
  }

  _toggleImeiFields() {
    const select = this.assetTypeTarget;
    const selectedOption = select.options[select.selectedIndex].text;
    if (imei_types.includes(selectedOption)) {
      this._showFields(this.imeiFieldTargets);
    } else {
      this._hideFields(this.imeiFieldTargets);
    }
  }

  _toggleComputerRelatedFields() {
    const select = this.assetTypeTarget;
    const selectedOption = select.options[select.selectedIndex].text;
    if (computer_name_types.includes(selectedOption)) {
      this._showFields(this.computerRelatedFieldTargets);
    } else {
      this._hideFields(this.computerRelatedFieldTargets);
    }
  }

  _togglePhoneNumberField() {
    const select = this.assetTypeTarget;
    const selectedOption = select.options[select.selectedIndex].text;
    if (phone_types.includes(selectedOption)) {
      this._showFields(this.phoneNumberFieldTargets);
    } else {
      this._hideFields(this.phoneNumberFieldTargets);
    }
  }

  _toggleStorageSizeField() {
    const select = this.assetTypeTarget;
    const selectedOption = select.options[select.selectedIndex].text;
    if (storage_size_types.includes(selectedOption)) {
      this._showFields(this.storageSizeFieldTargets);
    } else {
      this._hideFields(this.storageSizeFieldTargets);
    }
  }

  _toggleKeyboardLayoutField() {
    const select = this.assetTypeTarget;
    const selectedOption = select.options[select.selectedIndex].text;
    if (has_keyboard_types.includes(selectedOption)) {
      this._showFields(this.keyboardLayoutFieldTargets);
    } else {
      this._hideFields(this.keyboardLayoutFieldTargets);
    }
  }

  _toggleMacField() {
    const select = this.assetTypeTarget;
    const selectedOption = select.options[select.selectedIndex].text;
    if (mac_types.includes(selectedOption)) {
      this._showFields(this.macFieldTargets);
    } else {
      this._hideFields(this.macFieldTargets);
    }
  }

  updateTemplate() {
    if (this.hasImeiFieldTarget) this._toggleImeiFields();
    if (this.hasComputerRelatedFieldTarget) this._toggleComputerRelatedFields();
    if (this.hasPhoneNumberFieldTarget) this._togglePhoneNumberField();
    if (this.hasStorageSizeFieldTarget) this._toggleStorageSizeField();
    if (this.hasKeyboardLayoutFieldTarget) this._toggleKeyboardLayoutField();
    if (this.hasMacFieldTarget) this._toggleMacField();
  }

  connect() {
    this.updateTemplate();
  }
}
