import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['fileInput', 'uploadForm', 'uploadSpinner'];

  submit() {
    if (this.hasUploadSpinnerTarget) this.uploadSpinnerTarget.classList.remove('d-none');
    Rails.fire(this.uploadFormTarget, 'submit');
  }

  getFileInput(e) {
    e.preventDefault();
    this.fileInputTarget.click();
  }

  handleDragEnterOver(e) {
    e.preventDefault();
    e.stopPropagation();
    this.element.classList.add('border-primary');
  }

  handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    this.element.classList.remove('border-primary');
  }

  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.element.classList.remove('border-primary');

    const dt = e.dataTransfer;
    this.fileInputTarget.files = dt.files;
    this.submit();
  }
}
