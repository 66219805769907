/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

window.PaddleState = {};

export default class extends Controller {
  static values = { config: Object };

  static targets = [
    'currencyLabel',
    'subtotal',
    'tax',
    'total',
    'discountLine',
    'discount',
    'recurringPriceLine',
    'recurringPrice',
  ];

  _updatePrices(data) {
    const getSymbol = (currency) => {
      const symbol = new Intl.NumberFormat('en', { style: 'currency', currency }).formatToParts('').find((x) => x.type === 'currency');
      return symbol && symbol.value;
    };

    const { total, total_tax } = data.eventData.checkout.prices.customer;
    const subtotal = total - total_tax;

    this.currencyLabelTargets.forEach((currencyLabel) => {
      currencyLabel.innerHTML = getSymbol(data.eventData.checkout.prices.customer.currency);
    });

    this.subtotalTarget.innerHTML = subtotal.toFixed(2);
    this.taxTarget.innerHTML = data.eventData.checkout.prices.customer.total_tax;
    this.totalTarget.innerHTML = data.eventData.checkout.prices.customer.total;

    if (data.eventData.checkout.recurring_prices) {
      const recurringTotal = data.eventData.checkout.recurring_prices.customer.total;
      const intervalType = data.eventData.checkout.recurring_prices.interval.type;
      const intervalCount = data.eventData.checkout.recurring_prices.interval.length;
      const {
        net,
        tax,
        gross_discount,
        gross_after_discount,
      } = data.eventData.checkout.recurring_prices.customer.items[0].line_price;

      if (gross_discount) {
        this.subtotalTarget.innerHTML = net.toFixed(2);
        this.taxTarget.innerHTML = tax.toFixed(2);
        this.discountTarget.innerHTML = gross_discount.toFixed(2);
        this.totalTarget.innerHTML = gross_after_discount.toFixed(2);
        this.discountLineTarget.classList.remove('d-none');
      }

      const recurring = [
        recurringTotal,
        ' / ',
      ];

      if (intervalCount > 1) {
        recurring.push(intervalCount, ' ', intervalType, 's');
      } else {
        recurring.push(intervalType);
      }

      this.recurringPriceTarget.innerHTML = recurring.join('');
      this.recurringPriceLineTarget.classList.remove('d-none');
    }
  }

  _reloadPage() {
    if (window?.PaddleState?.initialized === true) location.reload();
  }

  connect() {
    this._reloadPage();

    Paddle.Setup({
      vendor: this.configValue.paddleVendorId,
      eventCallback: (eventData) => this._updatePrices(eventData),
    });

    window.PaddleState.initialized = true;

    if (this.configValue.isPaddleSandbox) {
      Paddle.Environment.set('sandbox');
    }

    Paddle.Checkout.open({
      method: 'inline',
      frameTarget: 'paddle-checkout-container',
      product: this.configValue.paddlePlanId,
      // email: this.configValue.userEmail,
      // country: this.configValue.userCountry,
      // postcode: this.configValue.userPostcode,
      allowQuantity: true,
      quantity: this.configValue.membersCount,
      disableLogout: true,
      frameInitialHeight: 450,
      frameStyle: 'width:100%; min-width:286px; background-color: transparent; border: none;',
      passthrough: `{"user_uuid": "${this.configValue.userUuid}", "company_uuid": "${this.configValue.companyUuid}", "company": "${this.configValue.company}"}`,
      title: this.configValue.title,
      message: this.configValue.message,
      success: this.configValue.successUrl,
    });
  }
}
