import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

window.LocationsStore = [];

export default class extends Controller {
  static targets = [
    'deviceSearch',
    'deviceType',
    'location',
    'deviceList',
    'spinner',
  ];

  handleLocationChange() {
    const selectedLocationIds = Array.from(this.locationTarget.selectedOptions)
                                     .map((option => option.value))
                                     .filter((element) => element.length > 0);
    if (selectedLocationIds.length > 0) {
      window.LocationsStore = selectedLocationIds;
      this.deviceSearch();
      return;
    }

    if (window.LocationsStore.length > 0) this.deviceSearch();
  }

  deviceSearch() {
    const spinner = this.spinnerTarget;
    spinner.classList.remove('d-none');
    this.deviceListTarget.innerHTML = '';

    const params = new URLSearchParams();
    params.append('q[filter_by_fragment]', this.deviceSearchTarget.value);
    params.append('q[device_type_name_eq]', this.deviceTypeTarget.value);

    const locationIds = Array.from(this.locationTarget.selectedOptions)
                             .map((option => option.value));
    locationIds.forEach((id) => params.append('q[locations_id_in][]', id));
    const url = `/reminders/physical_assets.js?${params}`;

    Rails.ajax({
      url,
      type: 'GET',
      success(data) {
        spinner.classList.add('d-none');
      },
      error(data) {
        alert(data.responseText);
      },
    });
  }

  connect() {
    this.deviceSearch();
  }
}
