/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['actionPanel'];

  openActionPanel({ target: { value: panelId } }) {
    let panel = null;

    this.actionPanelTargets.forEach((p) => {
      p.classList.remove('show', 'active');
      if (p.dataset.panelId === panelId) panel = p;
    });

    if (panel) {
      const collapse = new bootstrap.Collapse(panel);
      collapse.show();
    }
  }

  focusOnFirstInput(e) {
    e.currentTarget.querySelector('input[type=text]').focus();
  }
}
