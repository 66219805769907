/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['edit', 'modal', 'editModal', 'formFrame'];

  static values = {
    sourceId: String,
  };

  submitEnd(event) {
    const fr = event.detail.fetchResponse;

    fr.response.json().then((json) => {
      const { id, name, company_id } = json;
      this.select(id, name, { company_id });
    });
  }

  select(id, name, others = {}) {
    this.dispatch('select', { detail: { value: id, text: name, others } });
    this.hideModal();
  }

  hideModal() {
    const modal = bootstrap.Modal.getInstance(this.modalTarget);
    modal?.hide();
    const editModal = bootstrap.Modal.getInstance(this.editModalTarget);
    editModal?.hide();
  }

  showEdit(e) {
    const select = e.target;
    const selectedOptionEl = select.querySelector('option');
    const { company_id } = selectedOptionEl.dataset;
    const isLocal = Number(company_id) > 0;
    const { textContent: text, value } = select;
    if (isLocal) {
      this.editTarget.classList.remove('d-none');
      this.editTarget.href = `/app_brands/${value}/edit?source_id=${this.sourceIdValue}`;
    } else {
      this.editTarget.classList.add('d-none');
      this.editTarget.href = '#';
    }
  }
}
