import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'planType',
    'endDate',
  ];

  toggleFieldsNotInUse() {
    const planType = this.planTypeTargets.find((input) => input.checked);
    if (planType.value === 'rolling') {
      this.endDateTarget.classList.add('d-none');
    } else {
      this.endDateTarget.classList.remove('d-none');
    }
  }

  connect() {
    this.toggleFieldsNotInUse();
  }
}
