/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-alert */
/* eslint-disable no-console */

import { Controller } from '@hotwired/stimulus';

const LOADER_HTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
const ERROR_HTML = '<i class="fa-regular fa-bug"></i>';
const normalizeId = (recordId) => parseInt(recordId.match(/\d+/)[0], 10);

export default class extends Controller {
  static targets = [
    'actionsCounter', 'submitBtn', // SectionControlls
    'bulkActionsSelectAll', 'bulkActionsSelect', 'bulkActionsPanel', 'bulkActionsSelectLabel', // Bulk Actions
  ];

  static values = {
    resolvedRecords: Object,
    totalCount: Number,
    submitSectionPath: String,
    selectedForBulkAction: Object,
  };
  /*
    NOTE: resolvedRecordsValue = {
      766466752: 'add_person_and_assign',
      644147059: 'skip'
    };
  */

  action({ target: { name: recordId, value: action } }) {
    recordId = normalizeId(recordId);
    const oldCount = this.currentCount;
    this.resolvedRecordsValue = { ...this.resolvedRecordsValue, [recordId]: action };
    if (oldCount === this.currentCount) return;

    this._updateCounter();
    this._updateSubmitBtn();
  }

  async submitSection(e) {
    e.preventDefault();
    const isValid = this._validateInputs();
    if (!isValid) return;

    this._disableAllInputs();
    this._setSubmitBtnLoading();

    const sectionData = this._getSectionData();

    try {
      const response = await fetch(this.submitSectionPathValue, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify({ section_data: sectionData }),
      });

      if (!response.ok) {
        this._setSubmitBtnError();
        alert('Something went wrong. Please contact support.');
        return;
      }
      const body = await response.text();
      Turbo.renderStreamMessage(body);
    } catch (error) {
      this._setSubmitBtnError();
      console.error(error);
      alert('Something went wrong. Please contact support.');
    }
  }

  _updateCounter() {
    this.actionsCounterTarget.innerText = `${this.currentCount} / ${this.totalCountValue}`;
  }

  _updateSubmitBtn() {
    this.submitBtnTarget.disabled = this.totalCountValue !== this.currentCount;
  }

  _disableAllInputs() {
    this
      .element
      .querySelectorAll('input[type=radio], input[type=text], input[type=checkbox]')
      .forEach((input) => { input.disabled = true; });
  }

  _validateInputs() {
    let isValid = true;
    this
      .element
      .querySelectorAll('input[type=text]')
      .forEach((input) => {
        const recordId = normalizeId(input.dataset.recordId);
        const hasUserFields = this.resolvedRecordsValue[recordId] === 'add_person_and_assign';
        if (!hasUserFields) return;

        if (input.value.trim() === '') {
          input.classList.add('is-invalid');
          isValid = false;
        } else {
          input.classList.remove('is-invalid');
        }
      });
    return isValid;
  }

  _setSubmitBtnLoading() {
    this.submitBtnTarget.innerHTML = `${LOADER_HTML} ${this.submitBtnTarget.innerText}`;
    this.submitBtnTarget.disabled = true;
  }

  _setSubmitBtnError() {
    this.submitBtnTarget.innerHTML = `${ERROR_HTML} Submission failed`;
    this.submitBtnTarget.classList.remove('btn-primary');
    this.submitBtnTarget.classList.add('btn-danger');
    this.submitBtnTarget.disabled = true;
  }

  _getSectionData() {
    return Object.entries(this.resolvedRecordsValue).reduce((result, [recordId, action]) => {
      if (action === 'add_person_and_assign') {
        const recordInputs = this.element.querySelectorAll(`input[data-record-id="action_assignees_import_emails_exist_in_list_but_not_in_setyl_${recordId}"]`);
        const userParams = [...recordInputs].reduce((acc, input) => {
          const name = input.name.replace(/user\[(.+)\]/, '$1');
          return { ...acc, [name]: input.value };
        }, {});

        return { ...result, [recordId]: { action, ...userParams } };
      }

      return { ...result, [recordId]: { action } };
    }, {});
  }

  get currentCount() {
    return Object.keys(this.resolvedRecordsValue).length;
  }

  bulkAction({ params: { action } }) {
    const self = this;

    self
      .element.querySelectorAll(`input[type=radio][value=${action}]`)
      .forEach((input) => {
        const recordId = normalizeId(input.id);
        if (self.selectedForBulkActionsValue[recordId]) input.click();
      });

    self.bulkActionsSelectTargets.forEach((checkbox) => {
      checkbox.checked = false;
      const recordId = normalizeId(checkbox.id);
      if (self.selectedForBulkActionsValue[recordId]) {
        self.selectedForBulkActionsValue = { ...self.selectedForBulkActionsValue, [recordId]: false };
        self.resolvedRecordsValue = { ...self.resolvedRecordsValue, [recordId]: action };
      }
    });

    self._updateBulkActions();
    self._updateCounter();
    self._updateSubmitBtn();
  }

  bulkActionsSelectTargetConnected({ id }) {
    const recordId = normalizeId(id);
    this.selectedForBulkActionsValue = { [recordId]: false, ...this.selectedForBulkActionsValue };
  }

  bulkActionsToggleSelectAll({ currentTarget: { checked } }) {
    const self = this;

    self.bulkActionsSelectTargets.forEach((checkbox) => {
      checkbox.checked = checked;
      const recordId = normalizeId(checkbox.id);
      self.selectedForBulkActionsValue[recordId] = checked;
    });

    self._updateBulkActions();
  }

  bulkActionsToggleSelect({ currentTarget: { id, checked } }) {
    const recordId = normalizeId(id);
    this.selectedForBulkActionsValue[recordId] = checked;
    this._updateBulkActions();
  }

  bulkActionsSelectLabelTargetConnected({ innerText }) {
    this.selectAllText = innerText;
  }

  _updateBulkActions() {
    const values = Object.values(this.selectedForBulkActionsValue);
    const totalCount = values.length;
    const selectedCount = values.filter(Boolean).length;

    if (selectedCount <= 0) {
      this.bulkActionsPanelTarget.classList.add('d-none');
      this.bulkActionsSelectLabelTarget.innerText = this.selectAllText;
    } else {
      this.bulkActionsPanelTarget.classList.remove('d-none');
      this.bulkActionsSelectLabelTarget.innerText = `${this.selectAllText} (${selectedCount} / ${totalCount})`;
    }

    if (selectedCount === 0) {
      this.bulkActionsSelectAllTarget.checked = false;
      this.bulkActionsSelectAllTarget.indeterminate = false;
    } else if (selectedCount < totalCount) {
      this.bulkActionsSelectAllTarget.checked = true;
      this.bulkActionsSelectAllTarget.indeterminate = true;
    } else {
      this.bulkActionsSelectAllTarget.indeterminate = false;
      this.bulkActionsSelectAllTarget.checked = true;
    }
  }
}
