import Appsignal from "@appsignal/javascript";

let appsignal;

const appSignalMeta = document.querySelector('meta[name="app-signal"]');
if (appSignalMeta) {
  const appSignalKey = appSignalMeta.content;
  appsignal = new Appsignal({
    key: appSignalKey
  });
} else {
  console.warn("Meta tag 'app-signal' not found on the page.");
}

export { appsignal };
