/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'arrowUp',
    'arrowDown',
    'menuItem',
  ];

  _hideIfAllItemsCollapsed() {
    const divs = this.element.querySelectorAll('.accordion-collapse');
    const displayedItems = Array.from(divs).filter((element) => element.classList.contains('show'));
    const isAnyDisplayed = displayedItems.length > 0;

    if (!isAnyDisplayed) this._hideAll();
  }

  rotate(e) {
    this._hideAll();
    e.currentTarget.querySelector('.caret-up').classList.toggle('d-none');
    e.currentTarget.querySelector('.caret-down').classList.toggle('d-none');

    // NOTE: No other option to see if bootstrap has already collapsed the containers
    setTimeout(this._hideIfAllItemsCollapsed.bind(this), 100);
  }

  _hideAll() {
    this.arrowUpTargets.forEach((element) => element.classList.add('d-none'));
    this.arrowDownTargets.forEach((element) => element.classList.remove('d-none'));
  }

  _showActive() {
    const activeItems = this.menuItemTargets.filter((menuItem) => menuItem.classList.contains('active'));
    if (activeItems.length === 0) return;

    activeItems[0].querySelector('.caret-up').classList.remove('d-none');
    activeItems[0].querySelector('.caret-down').classList.add('d-none');
  }

  connect() {
    this._hideAll();
    this._showActive();
  }
}
