import { Turbo } from '@hotwired/turbo-rails';

const { adapter } = Turbo.navigator.delegate;

document.addEventListener('turbo:before-fetch-request', function (event) {
  const { target } = event;
  if (!(target instanceof HTMLElement)) return;

  adapter.formSubmissionStarted(this);
});

document.addEventListener('turbo:before-fetch-response', function () {
  adapter.formSubmissionFinished(this);
});
