import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['reason', 'saleCost', 'certificateNumber', 'notesLabel'];

  switchVisibility() {
    this.saleCostTarget.classList.add('d-none');
    this.certificateNumberTarget.classList.add('d-none');

    if (this.reasonTarget.value === 'sold') {
      this.saleCostTarget.classList.remove('d-none');
    } else if (this.reasonTarget.value === 'certified_destroyed') {
      this.certificateNumberTarget.classList.remove('d-none');
    }

    if (this.reasonTarget.value === 'lost') {
      this.notesLabelTarget.children[0].innerHTML = '*';
    } else {
      this.notesLabelTarget.children[0].innerHTML = '';
    }
  }

  connect() {
    this.switchVisibility();
  }
}
