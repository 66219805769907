/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import { Html5QrcodeScanner, Html5QrcodeScanType, Html5QrcodeSupportedFormats } from 'html5-qrcode';

const MATCH_REGEX = /^setyl\.it\//gi;

export default class extends Controller {
  static targets = [
    'reader',
    'input',
    'rescanBtn',
  ];

  _trimDomainName(text) {
    return text.replace(MATCH_REGEX, '');
  }

  _setInputValue(value) {
    this.inputTarget.type = 'text';
    this.inputTarget.classList.remove('numeric');
    this.inputTarget.value = value;
  }

  _pauseReader() {
    this.html5QrcodeScanner.pause(true);
  }

  _stopReader() {
    if (this.html5QrcodeScanner) this.html5QrcodeScanner.clear();
  }

  _onScanSuccess(decodedText) {
    this._pauseReader();
    const value = this._trimDomainName(decodedText);
    this._setInputValue(value);
    this.stopScanning();
  }

  _initializeReader() {
    const reader_id = 'reader';
    const config = {
      fps: 10,
      qrbox: { width: 250, height: 250 },
      rememberLastUsedCamera: true,
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
    };

    this.html5QrcodeScanner = new Html5QrcodeScanner(reader_id, config, false);
    this.html5QrcodeScanner.render(this._onScanSuccess.bind(this));
  }

  rescan(e) {
    e.preventDefault();
    this._initializeReader();
    this.rescanBtnTarget.classList.add('d-none');
    this.readerTarget.classList.remove('d-none');
  }

  resumeScanning() {
    this.html5QrcodeScanner.resume();
  }

  stopScanning() {
    this._stopReader();
    this.readerTarget.classList.add('d-none');
    this.rescanBtnTarget.classList.remove('d-none');
  }

  connect() {
    this._initializeReader();
  }

  disconnect() {
    this.stopScanning();
  }
}
