/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import { csrfToken } from '@rails/ujs';

export default class extends Controller {
  static values = {
    off: Array,
    on: Array,
    state: Boolean,
  };

  static targets = ['input', 'label'];

  onChange() {
    this._disableInput();
    const [label, path] = this.stateValue ? this.offValue : this.onValue;

    const self = this;
    fetch(path, {
      method: 'PATCH',
      headers: { 'X-CSRF-Token': csrfToken() },
      credentials: 'same-origin',
    }).then(() => {
      self.stateValue = !self.stateValue;
      self._setLabel(label);
    }).finally(() => self._enableInput());
  }

  _setLabel(value) {
    this.labelTarget.innerHTML = value;
  }

  _disableInput() {
    this.inputTarget.setAttribute('disabled', true);
  }

  _enableInput() {
    this.inputTarget.removeAttribute('disabled');
  }
}
